import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanLoad, Route, UrlSegment, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService, AuthState } from '../services/auth.service';

@Injectable()
export class EnterpriseGuard implements CanActivate, CanLoad {
  constructor(private auth: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const authState = this.auth.getAuthState();
    if (authState === AuthState.Anonymous) {
      return this.router.parseUrl('/rankings');
    }
    return true;
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const authState = this.auth.getAuthState();
    if (authState === AuthState.Anonymous) {
      return this.router.parseUrl('/rankings');
    }
    return true;
  }
}
