import {
  Component,
  ChangeDetectionStrategy,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { map, throttleTime } from 'rxjs/operators';

@Component({
  selector: 'app-scroll-to-top',
  templateUrl: './scroll-to-top.component.html',
  styleUrls: ['./scroll-to-top.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScrollToTopComponent implements OnDestroy, OnChanges {
  scrollSubject: ReplaySubject<number>;
  showScrollToTop$: Observable<boolean>;

  @Input() target: HTMLElement;

  constructor(private element: ElementRef<HTMLElement>) {
    this.scrollSubject = new ReplaySubject(1);

    this.showScrollToTop$ = this.scrollSubject.pipe(
      throttleTime(66),
      map(scroll => {
        const elementTop = this.element.nativeElement.offsetTop;
        const viewportTop = scroll;
        let height: number;
        if (this.target) height = this.target.offsetHeight;
        else height = window.innerHeight;

        const viewportBottom = viewportTop + height;

        const visibleHeight = viewportBottom - elementTop;
        const screenHeight = viewportBottom - viewportTop;

        if (visibleHeight > screenHeight * 2) {
          return true;
        } else {
          return false;
        }
      })
    );

    this.onScroll = this.onScroll.bind(this);
  }

  ngOnDestroy(): void {
    if (this.target) this.target.removeEventListener('scroll', this.onScroll);
    else window.removeEventListener('scroll', this.onScroll);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.target) {
      const prev: HTMLElement | undefined = changes.target.previousValue;
      const curr: HTMLElement | undefined = changes.target.currentValue;
      if (prev) {
        prev.removeEventListener('scroll', this.onScroll);
      } else {
        window.removeEventListener('scroll', this.onScroll);
      }
      if (curr) {
        curr.addEventListener('scroll', this.onScroll);
      } else {
        window.addEventListener('scroll', this.onScroll);
      }
    }
  }

  scrollToTop() {
    if (this.target) {
      this.target.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    } else {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }

  onScroll(this: ScrollToTopComponent) {
    let scrollY: number;
    if (this.target) scrollY = this.target.scrollTop;
    else scrollY = window.scrollY;

    this.scrollSubject.next(scrollY);
  }
}
