import { Component, Inject, forwardRef, Optional } from '@angular/core';
import {
  TypedBaseWidget,
  NgAisInstantSearch,
  NgAisIndex
} from 'angular-instantsearch';

import connectClearRefinements, {
  ClearRefinementsWidgetDescription,
  ClearRefinementsConnectorParams
} from 'instantsearch.js/es/connectors/clear-refinements/connectClearRefinements';

@Component({
  selector: 'app-clear-refinements',
  templateUrl: './clear-refinements.component.html',
  styleUrls: ['./clear-refinements.component.scss']
})
export class ClearRefinementsComponent extends TypedBaseWidget<
  ClearRefinementsWidgetDescription,
  ClearRefinementsConnectorParams
> {
  public state: ClearRefinementsWidgetDescription['renderState']; // Rendering options
  constructor(
    @Inject(forwardRef(() => NgAisIndex))
    @Optional()
    public parentIndex: NgAisIndex,
    @Inject(forwardRef(() => NgAisInstantSearch))
    public instantSearchInstance: NgAisInstantSearch
  ) {
    super('ClearRefinements');
  }
  ngOnInit() {
    this.createWidget(connectClearRefinements, {
      // instance options
    });
    super.ngOnInit();
  }
}
