import { StringMap } from '../types/string-map';

export const NOTIFICATION_OPTIONS = [
  {
    header: 'Risk Overview',
    options: [
      { label: 'Overall Risk Change', key: 'overallRiskChange' },
      { label: 'IVT Change', key: 'ivtChange' },
      { label: 'Viewability Change', key: 'viewabilityChange' }
    ]
  },
  {
    header: 'Advisory',
    options: [
      { label: 'App-ads.txt', key: 'appAdsTxt' },
      { label: 'Blocklisted', key: 'blocklisted' },
      { label: 'COPPA Violation Risk', key: 'coppaViolationRisk' },
      { label: 'App Permission Risk', key: 'appPermissionRisk' }
    ]
  },
  {
    header: 'Brand Safety',
    options: [{ label: 'Brand Safety', key: 'brandSafety' }]
  },
  {
    header: 'IVT',
    options: [
      { label: 'GIVT', key: 'givt' },
      { label: 'SIVT', key: 'sivt' }
    ]
  },
  {
    header: 'Traffic',
    options: [
      { label: 'DAU', key: 'dau' },
      { label: 'MAU', key: 'mau' },
      { label: 'Ad Spend', key: 'apSpend' },
      { label: 'Spoofing Risk', key: 'spoofingRisk' }
    ]
  },
  {
    header: 'Developer',
    options: [
      { label: 'Privacy Policy', key: 'privacyPolicy' },
      { label: 'Terms and Conditions', key: 'tac' },
      { label: 'Location', key: 'location' }
    ]
  },
  {
    header: 'Inventory',
    options: [{ label: 'Impression Volume', key: 'impressionVolume' }]
  },
  {
    header: 'Authorized Seller',
    options: [{ label: 'Authorized Seller', key: 'authorizedSeller' }]
  }
];

export const NOTIFICATION_CHANNELS = [
  { label: 'Email', key: 'email' },
  { label: 'Slack', key: 'slack' },
  { label: 'In-app', key: 'inApp' }
];

export const NOTIFICATION_FREQUENCY = [
  { label: 'Weekly', value: 'weekly' },
  { label: 'Monthly', value: 'monthly' }
];

export const METRICS: StringMap = {
  effectiveDate: 'metric_1',
  privacyLawsCompliance: 'metric_2',
  wordCount: 'metric_3',
  appVersion: 'metric_4',
  sensitivePermission: 'metric_5',
  privacyUrl: 'metric_6',
  overallRiskChange: 'metric_7',
  ivtChange: 'metric_8',
  viewabilityChange: 'metric_9',
  appAdsTxt: 'metric_10',
  blocklisted: 'metric_11',
  coppaViolationRisk: 'metric_12',
  appPermissionRisk: 'metric_13',
  brandSafety: 'metric_14',
  givt: 'metric_15',
  sivt: 'metric_16',
  dau: 'metric_17',
  mau: 'metric_18',
  apSpend: 'metric_19',
  spoofingRisk: 'metric_20',
  privacyPolicy: 'metric_21',
  tac: 'metric_22',
  location: 'metric_23',
  impressionVolume: 'metric_24',
  authorizedSeller: 'metric_25'
};

export const CHANNELS: StringMap = {
  email: 'email',
  slack: 'slack',
  inApp: 'ui'
};
