import { NgModule } from '@angular/core';
import { RoundPipe } from './round.pipe';
import { RoundSigPipe } from './roundsig.pipe';
import { NumPipe, PercPipe } from './perc.pipe';
import { ShowPipe } from './show.pipe';
import { SafeHtmlPipe } from './safehtml.pipe';
import { YesnoPipe } from './yesno.pipe';
import { DurationFormatPipe } from './durationformat.pipe';
import { CapitalizePipe } from './capitalize.pipe';
import { RounddecimalPipe, RoundpercentPipe } from './rounddecimal.pipe';
import { UrlfixPipe } from './urlfix.pipe';
import { MonthstoyearsPipe } from './monthstoyears.pipe';
import { AbPercPipe } from './abperc.pipe';
import { RankinggriddatahtmlPipe } from './rankinggriddatahtml.pipe';
import { RoundSigRangePipe } from './roundsigrange.pipe';
import { InsightsRouterLinkPipe } from './insightsrouterlink.pipe';
import { PlatformAwareScalerPipe } from './platform-aware-scaler.pipe';
import { DetectednotdetectedPipe } from './detectednotdetected.pipe';
import { AbsolutePipe } from './absolutepipe';
import { SkipColumnPipe } from './skip-column.pipe';
import { ValidDatePipe } from './valid-date.pipe';
import { IconMapperPipe } from './icon-mapper.pipe';
import { ContainsValuePipe } from './contains-value';

@NgModule({
  imports: [],
  declarations: [
    RoundPipe,
    RoundSigPipe,
    RoundSigRangePipe,
    NumPipe,
    ShowPipe,
    SkipColumnPipe,
    PercPipe,
    SafeHtmlPipe,
    YesnoPipe,
    DurationFormatPipe,
    CapitalizePipe,
    RounddecimalPipe,
    UrlfixPipe,
    RoundpercentPipe,
    MonthstoyearsPipe,
    AbPercPipe,
    RankinggriddatahtmlPipe,
    InsightsRouterLinkPipe,
    DetectednotdetectedPipe,
    PlatformAwareScalerPipe,
    AbsolutePipe,
    ValidDatePipe,
    IconMapperPipe,
    ContainsValuePipe
  ],
  exports: [
    RoundPipe,
    RoundSigPipe,
    RoundSigRangePipe,
    NumPipe,
    ShowPipe,
    SkipColumnPipe,
    PercPipe,
    SafeHtmlPipe,
    YesnoPipe,
    DurationFormatPipe,
    CapitalizePipe,
    RounddecimalPipe,
    UrlfixPipe,
    RoundpercentPipe,
    MonthstoyearsPipe,
    AbPercPipe,
    RankinggriddatahtmlPipe,
    InsightsRouterLinkPipe,
    DetectednotdetectedPipe,
    PlatformAwareScalerPipe,
    AbsolutePipe,
    ValidDatePipe,
    IconMapperPipe,
    ContainsValuePipe
  ]
})
export class PipesModule {}
