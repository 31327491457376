import { Component } from '@angular/core';
import { KcsBaseComponent } from '../kcs-base/kcs-base.component';
import { CONTENT_FILTERS, SEARCH_CONTEXT } from '../ALGOLIA_CONFIG';

@Component({
  selector: 'app-kcs-title-search',
  templateUrl: './kcs-title-search.component.html',
  styleUrls: [
    '../kcs-base/kcs-base-common.scss',
    '../kcs-base/kcs-base.component.scss',
    './kcs-title-search.component.scss'
  ]
})
export class KcsTitleSearchComponent extends KcsBaseComponent {
  keywordFilters = CONTENT_FILTERS;
  searchContext = SEARCH_CONTEXT.SIMPLE_SEARCH;
  filterKey = 'appStore';
  filterUrlKey = undefined;
  config = this.makeConfig();

  isFreemium: boolean;

  ngOnInit() {
    super.ngOnInit();
    if (!this.showCoppaFilter()) {
      this.keywordFilters = CONTENT_FILTERS.filter(
        f => f.attribute != 'coppaTargetChildren'
      );
    }

    this.isFreemium = this.isFreemiumUser();
    this.restrictSearchableAttributes = [
      'title',
      'adDomain',
      'bundleId',
      'appId',
      'trackId',
      'mappedBundleIds',
      'publisherName'
    ];
  }
}
