import { KeyValue } from './KeyValue';

export var TooltipData: KeyValue[] = [
    {key: 'true-reach', value: 'The estimated number of unique users per 1 Million internet users that can be reached on this domain.'},
    {key: 'overview-inventory-available', value: 'The estimated number of monthly impressions this domain makes available programmaticaly based on the data updated weekly.'},
    {key: 'overview-est-ct', value: 'Estimated Average Click Through Rate'},
    {key: 'site-risk-private-domain', value: 'Private Domains are those that are registered as anonymous in the Whois site registration data'},
    {key: 'site-risk-corporate-email', value: '"True" means Whois registration was done with a corporate email address; "false" means Whois registration was done with a personal email address'},
    {key: 'site-risk-brand-safety', value: 'Risk level for various brand safety categories'},
    {key: 'social-media-engagement', value: 'Active engagement levels for various social platforms'},
    {key: 'non-human-traffic-givt', value: 'General Invalid Traffic - Deterministically judged to be non-human traffic; high to very high risk'},
    {key: 'non-human-traffic-sivt', value: 'Sophisticated Invalid Traffic - Probabilistically judged to be non-human traffic; moderate to high risk traffic'},
    {key: 'seller-share-ov-total-inventory', value: 'The estimated number of monthly programmatic impressions available for this domain from this seller'},
    {key: 'traffic-generation-search', value: 'The percent of this domain\'s traffic that comes from search engines' },
    {key: 'traffic-generation-direct', value: 'The percent of this domain\'s traffic that comes from a user directly typing the web address in their browser or clicking an existing bookmark' },
    {key: 'traffic-generation-display', value: 'The percent of this domain\'s traffic that comes from display advertising' },
    {key: 'traffic-generation-social', value: 'The percent of this domain\'s traffic that comes from social media' },
    {key: 'traffic-generation-email', value: 'The percent of this domain\'s traffic that comes from email' },
    {key: 'traffic-generation-discovery', value: 'The percent of this domain\'s traffic that comes from widgets or links to this site from other websites' },
    {key: 'traffic-generation-paid', value: 'The percent of this domain\'s traffic that comes from paid traffic generation sources' },
    {key: 'gsti-sellerName', value: 'The name of the seller and their rank among all sellers'},
    {key: 'gsti-finalScore', value: 'Weighted average of all quality scores'},
    {key: 'gsti-reachScore', value: 'Number of unique users available on sellers platform'},
    {key: 'gsti-networkScore', value: 'Based on domain quality represented by sellers or open exchanges'},
    {key: 'gsti-viewabilityScore', value: 'Determined by amount of ads in which 50% of pixels are on viewable browser space for at least one second'},
    {key: 'gsti-givtScore', value: 'General Invalid Traffic as defined by Media Ratings Council (MRC) guidelines such as data center traffic, bots, and spiders masquerading as legitimate users'},
    {key: 'gsti-sivtScore', value: 'Sophisticated Invalid Traffic as defined by Media Ratings Council (MRC) guidelines such as hijacked devices, URL spoofing, or falsely represented impressions'},
    {key: 'gsti-maskingScore', value: 'Determined by amount of inventory falsely represented as a different domain'},
    {key: 'overall-reputation-overview', value: 'Reputation Overview is updated monthly for the prior complete month'},
    {key: 'app-seller-name', value: 'The name of the seller or supply side platform offering programmatic ad impressions in the app'},
    {key: 'app-seller-inventory', value: 'The estimated total inventory available through each seller or supply side platform'},
    {key: 'app-seller-nht', value: 'The percentage of non-human traffic measured for each seller or supply side platform'},
    {key: 'app-seller-video', value: 'The percentage of video ads offered by the seller or supply side platform'},
    {key: 'app-seller-viewability', value: 'The average viewability percentage for ad impressions sold by each seller or supply side platform'},
    {key: 'autoreloader', value: 'Impressions with very periodic patterns that cannot be generated by a human.'},
    {key: 'blankUserAgent', value: 'The impression has an empty User Agent field.'},
    {key: 'clickFarm', value: 'An impression originating from a user who has been flagged as being associated with human click farm activity.'},
    {key: 'cookieStuffing', value: 'Activity from a cookie which has connected to the internet via a statistically significant inflated number of different IP Addresses.'},
    {key: 'datacenter', value: 'The User’s IP has a match in the Pixalate known Datacenter list.'},
    {key: 'datacenterProxy', value: 'The impression is from an intermediary proxy device, running in a datacenter, that exists to manipulate traffic counts, pass non-human or invalid traffic or which fails to comply with protocol.'},
    {key: 'deviceIdStuffing', value: 'Activity from a device which has connected to the internet via a statistically significant inflated number of different IP Addresses.'},
    {key: 'displayClickFraud', value: 'Clicks that are generated from the same browser or device at an inflated rate.'},
    {key: 'displayImpressionFraud', value: 'Impressions that are generated from the same browser or device at a statistically significant inflated rate.'},
    {key: 'doorwaySite', value: 'A user which has been flagged for accessing a given page or domain via multiple spoofed page referrers.'},
    {key: 'duplicateClicks', value: 'High volumes of duplicate clicks which may indicate an integration issue.'},
    {key: 'duplicateImpressions', value: 'High volumes of duplicate impressions which may indicate an integration issue.'},
    {key: 'fastClicker', value: 'Activity originating from users which generate clicks less than one second apart from their respective impression.'},
    {key: 'highCTRTraffic', value: 'Traffic associated with apps or apps demonstrating high risk CTR behavior.'},
    {key: 'highRiskApp', value: 'The impression is from an app which has been flagged for a high risk of invalid traffic.'},
    {key: 'highRiskDeviceId', value: 'The impression is from a device ID which has been flagged for a high risk of invalid traffic.'},
    {key: 'highRiskDomain', value: 'The impression is from a domain which has been flagged for a high risk of invalid traffic.'},
    {key: 'highRiskIP', value: 'The impression is from an IP Address which has been flagged for a high risk of invalid traffic.'},
    {key: 'hijackedSession', value: 'Activity originating from a device or browser which has a statistically significant inflated number of user sessions.'},
    {key: 'IABcrawler', value: 'Activity originating from bots that use a User Agent string which matches a User Agent on the list of IAB known crawlers.'},
    {key: 'IABdummyBot', value: 'Activity originating from bots that use a User Agent string which does not match any existing browser.'},
    {key: 'idioBots', value: 'Bots (or users) that change their User Agent string (spoofing), while keeping the same cookie.'},
    {key: 'IPObfuscation', value: 'An IP which has been spoofed such that the impression is rendered to a different IP address than the one originally offered.'},
    {key: 'locationObfuscation', value: 'Activity originating from an IP where multiple impressions deviate from the geographic location that is reported in the advertising transaction.'},
    {key: 'malware', value: 'The impression is from apps or pages known to host malware.'},
    {key: 'maskedIP', value: 'The IP of a user does not match the IP and the associated ISP reported in the advertising transaction.'},
    {key: 'phishing', value: 'The impression is from apps or pages associated with phishing tactics.'},
    {key: 'privateIP', value: 'The IP Address associated with the ad impression is from the private network space.'},
    {key: 'proxy', value: 'The impression is from an intermediary proxy device that exists to manipulate traffic counts, pass non-human or invalid traffic or which fails to comply with protocol.'},
    {key: 'publisherFraud', value: 'Publishers operating apps or apps which violate standard ad serving practices including, for example, stacked ads, high ad density and inflated impression counts.'},
    {key: 'smartbot', value: 'Bots (or users) that change their browser agent string (spoofing) and cookies very often under the same IP, creating low volume traffic or high volume traffic under configuration that looks like a busy enterprise network.'},
    {key: 'TOR', value: 'Traffic originating from a TOR network node.'},
    {key: 'videoClickFraud', value: 'Video ad clicks that are generated from the same browser or device at a statistically significant inflated rate.'},
    {key: 'videoImpressionFraud', value: 'Video ad impressions that are generated from the same browser or device at a statistically significant inflated rate.'},
    {key: 'Shares Info', value: 'if the app can share your personal information with third-parties, eg Instagram, Viber, and most social apps'},
    {key: 'Shares Location', value: 'if the app can show your location to other users, eg Instagram, Viber, and most social apps'},
    {key: 'Users Interact', value: 'if there’s user-generated content inside the app that may be unfiltered or uncensored by the developers, eg Instagram, Viber, and most social apps'},
    {key: 'Digital Purchases', value: 'if the app allows you to make purchases, eg Amazon Shopping'},
    {key: 'Unrestricted Internet', value: '- if the app has complete access to the Internet, eg Firefox, Chrome'},
    {key: 'user-overlap', value: 'The percentage of users of the given app that also visit the app shown here.'},
    {key: 'inventory-overlap', value: 'The percentage of impressions of the given app that were generated by the users that visited the app shown here.'}
];
