import {
  Component,
  Inject,
  forwardRef,
  Optional,
  Input,
  AfterViewInit,
  ViewChild,
  ElementRef,
  OnInit
} from '@angular/core';
import {
  TypedBaseWidget,
  NgAisInstantSearch,
  NgAisIndex
} from 'angular-instantsearch';
import Litepicker from 'litepicker';

import connectRange, {
  RangeWidgetDescription,
  RangeConnectorParams
} from 'instantsearch.js/es/connectors/range/connectRange';
import { ALGOLIA_INDEX } from '../../ALGOLIA_CONFIG';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent
  extends TypedBaseWidget<RangeWidgetDescription, RangeConnectorParams>
  implements OnInit, AfterViewInit
{
  @ViewChild('datepicker') datepicker: ElementRef;

  @Input() attribute: string | undefined;

  picker: any;
  noRange = false;

  public static parseDate(dateString: string) {
    const day = dateString.substring(0, 2);
    const month = dateString.substring(2, 4);
    const year = dateString.substring(4, 8);
    const date = new Date(`${year}-${month}-${day}`);

    return !isNaN(date.getTime()) ? date : undefined;
  }

  constructor(
    @Inject(forwardRef(() => NgAisIndex))
    @Optional()
    public parentIndex: NgAisIndex,
    @Inject(forwardRef(() => NgAisInstantSearch))
    public instantSearchInstance: NgAisInstantSearch,
    private route: ActivatedRoute
  ) {
    super('RangeInput');
  }

  ngOnInit() {
    this.createWidget(connectRange, {
      // instance options
      attribute: this.attribute
    });

    super.ngOnInit();
  }

  ngAfterViewInit() {
    const interval = setInterval(() => {
      const renderState =
        this.instantSearchInstance.instantSearchInstance.renderState[
          ALGOLIA_INDEX
        ];
      if (renderState && renderState.range[this.attribute].range) {
        clearInterval(interval);
        this.picker = new Litepicker({
          element: this.datepicker['nativeElement'],
          singleMode: false,
          resetButton: () => {
            let btn = document.createElement('button');
            btn.innerText = 'Clear';
            return btn;
          },
          setup: picker => {
            picker.on('clear:selection', () => {
              this.onDateChange(undefined, undefined);
            });

            picker.on('selected', (start: Date, end: Date) => {
              this.onDateChange(start.getTime(), end.getTime());
            });
          }
        });

        this._updatePickerStatus();

        const attParam = this.route.snapshot.queryParams[this.attribute];
        if (attParam) {
          const rangeValues = attParam.split(':');
          const startDate = DateRangePickerComponent.parseDate(rangeValues[0]);
          const endDate = DateRangePickerComponent.parseDate(rangeValues[1]);

          this.picker.setDateRange(startDate, endDate);
        }

        this.instantSearchInstance.change.subscribe({
          next: (event: any) => {
            if (
              !this.instantSearchInstance.instantSearchInstance.renderState[
                ALGOLIA_INDEX
              ].clearRefinements.canRefine
            ) {
              this.reset();
            }

            this._updatePickerStatus();
          }
        });
      }
    }, 500);
  }

  onDateChange(start: number, end: number) {
    this.state.refine([start, end]);
  }

  reset() {
    if (this.picker) {
      this.picker.clearSelection();
    }
  }

  showDatePicker() {
    this.picker.show();
  }

  private _updatePickerStatus() {
    const rangeMax = this.state.range.max;

    if (rangeMax == 0) {
      this.noRange = true;
      return;
    }

    const maxDate = this._getMaxDate(rangeMax);
    const minYear = new Date(this.state.range.min).getFullYear();

    this.picker.setOptions({
      minDate: this.state.range.min,
      maxDate: maxDate,
      dropdowns: {
        minYear: minYear,
        maxYear: maxDate.getFullYear(),
        months: true,
        years: true
      }
    });

    this.picker.gotoDate(maxDate);
    this.noRange = false;
  }

  private _getMaxDate(rangeMaxDate: number): Date {
    const current_date = new Date();
    if (rangeMaxDate > current_date.getTime()) {
      return current_date;
    }
    return new Date(rangeMaxDate);
  }
}
