import { Component } from '@angular/core';
import { KcsBaseComponent } from '../kcs-base/kcs-base.component';
import { PRIVACY_FILTERS, SEARCH_CONTEXT } from '../ALGOLIA_CONFIG';

@Component({
  selector: 'app-pp-keyword-contextual-search',
  templateUrl: './kcs-privacy-policy-search.component.html',
  styleUrls: [
    '../kcs-base/kcs-base-common.scss',
    '../kcs-base/kcs-base.component.scss'
  ]
})
export class KcsPrivacyPolicySearchComponent extends KcsBaseComponent {
  keywordFilters = PRIVACY_FILTERS;
  searchContext = SEARCH_CONTEXT.PRIVACY;
  filterKey = 'appStore';
  filterUrlKey = 'appStore';
  config = this.makeConfig();
}
