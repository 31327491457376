export class Follow {
  created: Date;
  followType: string;
  followId: string;
  userId: string;
  _temp: boolean;

  constructor ( userId: string, followId: string, followType: string ) {
    this.userId = userId;
    this.followId = followId;
    this.followType = followType;
    this._temp = true;
  }
}
