/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-slider-toggle',
  templateUrl: './slider-toggle.component.html',
  styleUrls: ['./slider-toggle.component.scss']
})
export class SliderToggleComponent implements OnInit {
  @Input()
  value: boolean;

  @Output() update: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  updateValue(newValue) {
    this.value = newValue;
    this.update.emit(newValue);
  }
}
