import {Injectable} from '@angular/core';
import {SHELL_COUNTRIES, SHELL_STATE} from './SHELL_COUNTRIES';

@Injectable()
export class CountryCodeService {
  getShellStateByCountryName(countryName: string): string {
    if (!countryName || countryName === 'N/A') {
      return SHELL_STATE.red;
    }
    const foundShellState = SHELL_COUNTRIES.filter(shell => shell.countries.indexOf(countryName.toLowerCase()) >= 0);
    if (!foundShellState || !foundShellState[0]) {
      return SHELL_STATE.green;
    }
    // TODO: how to do if valid country name?
    return foundShellState[0].state;
  }
}
