<div class="result {{viewType}} {{indexName}}">
  <a [routerLink]="hit.routerLink" class="result-icon">
    <img [src]="hit.appIcon | screenshot:hit.appStore:72" />
  </a>
  <ul class="result-meta result-meta-header">
    <li class="result-meta-item last-updated">
      Last updated on {{ hit.lastUpdatedDate | date:'MM/dd/yyyy' }}
    </li>
    <li class="result-meta-item mr-2">
      <button class="btn btn-link result-meta-link result-meta-item-block" mrt-attribute-btn [indexName]="indexName"
        attribute="appStore" [value]="hit.appStore" (click)="onFilterClick(hit.appStore)">
        <ng-container [ngSwitch]="hit.appStore">
          <ng-container *ngSwitchCase="'roku'">
            <img width="16" height="16" class="mr-1" src="/assets/images/roku.png" alt="Roku Icon">
            <span>Roku</span>
          </ng-container>
          <ng-container *ngSwitchCase="'firetv'">
            <img width="16" height="16" class="mr-1" src="/assets/images/firetv.png" alt="Fire TV Icon">
            <span>Fire TV</span>
          </ng-container>
          <ng-container *ngSwitchCase="'samsung'">
            <img width="16" height="16" class="mr-1" src="/assets/images/samsung.png" alt="Samsung Icon">
            <span>Samsung</span>
          </ng-container>
          <ng-container *ngSwitchCase="'tvos'">
            <img width="16" height="16" class="mr-1" src="/assets/images/tvos.png" alt="tvOS Icon">
            <span>tvOS</span>
          </ng-container>
          <ng-container *ngSwitchCase="'android'">
            <img width="16" height="16" class="mr-1" src="/assets/images/play-store.png" alt="Google Play Icon">
            <span>Google Play</span>
          </ng-container>
          <ng-container *ngSwitchCase="'ios'">
            <img width="16" height="16" class="mr-1" src="/assets/images/app-store.png" alt="App Store Icon">
            <span>App Store</span>
          </ng-container>
        </ng-container>
      </button>
    </li>
    <li class="result-meta-item">
      <span class="star-rating">
        <i class="fa" *ngFor="let star of hit.stars" [ngClass]="[ star ? 'fa-star' : 'fa-star-o' ]">
        </i>
      </span>
    </li>
  </ul>
  <div class="result-body">
    <h3 class="result-title" *ngIf="hit.title" enTooltip [title]="hit.title">
      <a [routerLink]="hit.routerLink">
        <ais-highlight attribute="title" [hit]="hit"></ais-highlight>
      </a>
    </h3>
    <ul class="result-meta">
      <li class="result-meta-item justify-content-between">
        <div class="result-meta-item-block developer">
          Developer: <span class="">{{ hit.publisherName }}</span>
        </div>
        <div class="result-meta-item-block" *ngIf="hit.developerCuntryCode">
          <button mrt-attribute-btn [indexName]="indexName" attribute="developerCountryName"
            [value]="hit.developerCountryName" class="btn btn-link p-0">
            <img class="country-flag" width="24"
              src="//cdn.pixalate.com/icons/countries/flat/48/{{ hit.developerCuntryCode }}.png" alt="Country Logo">
          </button>
        </div>
      </li>
      <li class="result-meta-item">
        <ng-container [ngSwitch]="hit.appStore">
          <ng-container *ngSwitchCase="'firetv' || 'roku' || 'samsung' || 'tvos'">
            Category:
            <button mrt-attribute-btn [indexName]="indexName" attribute="appStoreCategories"
              [value]="hit.appStoreCategories" class="btn btn-link result-meta-link">{{
              hit.appStoreCategories?.split(',')[0] }}</button>
          </ng-container>
          <ng-container *ngSwitchDefault>
            Category:
            <button mrt-attribute-btn [indexName]="indexName" attribute="iabPrimaryCategory"
              [value]="hit.iabPrimaryCategory" class="btn btn-link result-meta-link">{{ hit.iabPrimaryCategory
              }}</button>
          </ng-container>
        </ng-container>
      </li>
      <li class="result-meta-item" *ngIf="hit.downloads">
        Downloads: <b>{{ hit.downloads | roundsigrange }}</b>
      </li>
    </ul>
    <ul class="result-meta result-meta-body">
      <li class="result-meta-item" *ngIf='hit.coppaTargetChildren'>
        <img height="16" src='/assets/images/icon-child-audience.svg' />
        <button mrt-attribute-btn attribute="coppaTargetChildren" [indexName]="indexName"
          [value]="hit.coppaTargetChildren" class="btn btn-link result-meta-link">Child directed
        </button>
      </li>
      <li class="result-meta-item" *ngIf="hit.hasPrivacyLink">
        <img height="16" src="/assets/images/icon-indicator-yes-2.svg" />
        <button mrt-attribute-btn attribute="hasPrivacyLink" [indexName]="indexName" [value]="hit.hasPrivacyLink"
          class="btn btn-link result-meta-link">Privacy Policy detected
        </button>
      </li>
      <li class="result-meta-item">
        {{ hit.appStore === 'ios' ? hit.trackId : hit.bundleId || hit.appId }}
      </li>
    </ul>
    <p class="result-description">
      <ng-container *ngIf="hit._highlightResult['description']?.matchLevel == 'none'">
        <ais-highlight attribute="description" [hit]="hit"></ais-highlight>
      </ng-container>
      <ng-container
        *ngIf="hit?._highlightResult?.description?.matchLevel !== 'none' && hit?._snippetResult?.description?.value != ''">
        <div [innerHTML]="hit['_snippetResult']?.description?.value | stripNlnl"></div>
      </ng-container>
    </p>
    <p class="result-matched" *ngIf="hit.matched">
      {{ hit.matched }} keyword{{ hit.matched === 1 ? '' : 's' }} matched
    </p>
  </div>
</div>