/**
 * Get AppGroup based on appStore
 *
 * @param appStore
 * @returns appGroup
 */
export function getAppGroup(appStore: string) {
  if (['ios', 'android'].includes(appStore)) {
    return 'apps';
  } else if (['roku', 'firetv', 'samsung', 'tvos'].includes(appStore)) {
    return 'ctv';
  }
  return 'domains';
}
