import { Injectable } from '@angular/core';
import { EnvConfigService } from './env-config.service';

@Injectable()
export class BaseApiService extends EnvConfigService {
  getReportBaseUrl(yearPrefix?: string) {
    return `https://${this.getApiHost()}/services/${
      yearPrefix ? yearPrefix : '2018'
    }/Report`;
  }
  getRatingsBaseUrl(yearPrefix?: string) {
    return `https://${this.getApiHost()}/services/${
      yearPrefix ? yearPrefix : '2018'
    }/Ratings`;
  }
  getFreemiumBaseUrl(yearPrefix?: string) {
    return `https://${this.getApigeeHost()}/api/v1`;
  }
  getMrtBaseUrl() {
    return `https://${this.getApiHost()}`;
  }
  getAlertBaseUrl() {
    return `https://${this.getAlertHost()}`;
  }
  getUsersBaseUrl() {
    return `https://${this.getApiHost()}/users`;
  }

  getFollowersBaseUrl() {
    return `https://${this.getApiHost()}/followers`;
  }

  getApigeeBaseUrl(version?: string) {
    return `https://${this.getApigeeHost()}/api/${version ? version : 'v2'}`;
  }

  storeCache(res: any, dataCache: any, urlString: any) {
    if (res['status'] && res['status'] === 401) {
      // Do Nothing
    } else {
      dataCache[urlString] = JSON.parse(JSON.stringify(res));
    }
  }
}
