import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { PageMetaService } from 'app/services/page-meta.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SearchQueryEvent } from '../../new-navigation.component';

@Component({
  selector: 'app-kcs-overlay',
  templateUrl: './kcs-overlay.component.html',
  styleUrls: ['./kcs-overlay.component.scss']
})
export class KcsOverlayComponent implements OnInit {
  @Input() searchEvents: Observable<SearchQueryEvent>;
  @Input() isOverlay = false;

  @Output() close: EventEmitter<void> = new EventEmitter();

  searchQueryInjector: Observable<string>;

  pageName$: Observable<string>;

  constructor(private pageMeta: PageMetaService, public element: ElementRef) {
    this.pageName$ = pageMeta.currentPageName$;
  }

  ngOnInit(): void {
    this.searchQueryInjector = this.searchEvents.pipe(map(evt => evt.query));
  }

  @HostListener('window:keydown.escape') closeButtonClick() {
    this.close.emit();
  }
}
