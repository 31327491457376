import {Component} from '@angular/core';
import { EventBusService } from '../../../services/EventBusService';

declare var jQuery;

@Component({
    selector: 'app-fna-popup',
    templateUrl: './fna-popup.component.html',
    styleUrls: ['./fna-popup.component.css']
})

export class FnaPopupComponent {
    showNoMonthFound: boolean;
    showPopup: boolean;
    loadingMonth: string;
    showNoDomainFound: boolean;
    loadingDomain: string;
    showWhatsNew: boolean;
    showServerError: boolean;
    showImportCsvPopup: boolean;
    removeDuplicates: boolean;
    showDownloadCsvLimitPopup: boolean;
    showInvitePopup: boolean;
    inviteEmail: string;
    inviteMessage: string;
    emailInvite: string;
    inviting: boolean;

    constructor(public eventBus: EventBusService) {
        this.eventBus.showPopupEvent.subscribe(value => this.showPopupEvent(value));
        this.eventBus.showNoMonthFoundEvent.subscribe(value => this.showNoMonthFoundPopup(value));
        this.eventBus.showNoDomainFoundEvent.subscribe(value => this.showNoDomainFoundPopup(value));
        this.eventBus.showServerErrorEvent.subscribe(value => this.showServerErrorPopup());
        this.eventBus.showWhatsNewEvent.subscribe(value => {
            if (value) {
                // jQuery('#whatsNewModal').modal('show');
            }
        });
        this.eventBus.showImportCsvPopupEvent.subscribe((removeDuplicates: boolean) => {
          this.showImportCsvPopupEvent(removeDuplicates);
        });
        this.eventBus.showDownloadCsvLimitPopupEvent.subscribe(value => {
          this.showDownloadCsvLimitPopup = true;
        });
        this.eventBus.invitePopupEvent.subscribe((status: boolean) => {
            this.showInvitePopup = status;
            this.inviting = false;
            if (status) {
              // We clear previously inputed email, message
              this.inviteEmail = '';
              this.inviteMessage = '';
            }
        });
        this.eventBus.setInvitingEvent.subscribe((invitingStatus: boolean) => {
          this.inviting = invitingStatus;
        });
    }

    close() {
        this.showPopup = false;
    }

    showPopupEvent(value) {
        this.showPopup = value;
    }

    showServerErrorPopup() {
      this.showServerError = true;
    }

    showNoMonthFoundPopup(loadingMonth: string) {
      this.showNoMonthFound = true;
      this.loadingMonth = loadingMonth;
    }

    showNoDomainFoundPopup(loadingDomain: string) {
      this.showNoDomainFound = true;
      this.loadingDomain = loadingDomain;
    }

    contact() {
        window.location.href = 'mailto:sales-external@pixalate.com';
    }

    showImportCsvPopupEvent(removeDuplicates: boolean) {
      this.showImportCsvPopup = true;
      this.removeDuplicates = removeDuplicates;
    }

    importCsvClick() {
      this.showImportCsvPopup = false;
      this.eventBus.importCsvClickOnPopup(this.removeDuplicates);
    }

    inviteClick() {
        // console.log( this.inviteMessage );
        if (!this.inviting) {
          this.inviting = true;
          this.eventBus.sendInviteEmail(this.inviteEmail,this.inviteMessage);
        }

    }
}
