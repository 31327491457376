import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {routing} from './tooltip.module.routing';
import {PipesModule} from '../../pipes/pipes.module';
import {DirectivesModule} from '../../directives/directives.module';
import {TooltipComponent} from './tooltip.component';
import {TooltipWithIconComponent} from './tooltip-with-icon.component';


@NgModule({
  declarations: [
    TooltipComponent,
    TooltipWithIconComponent
  ],
  exports: [
    TooltipComponent,
    TooltipWithIconComponent
  ],
  imports: [
    PipesModule,
    CommonModule,
    DirectivesModule,
    routing
  ]
})
export class TooltipModule { }
