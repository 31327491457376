
import {Injectable} from '@angular/core';
import {BaseApiService} from './base-api-service';
import {HttpClient} from '@angular/common/http';
import {ChangeLogResponse} from '../models/ChangeLogResponse';

@Injectable()
export class ChangelogService extends BaseApiService {

  appChangeLogCache: [string, ChangeLogResponse];

  constructor(private http: HttpClient) {
    super();

    this.appChangeLogCache = ['empty', null];
  }

  getMobileAppChangeLog(appid: string, os: string, region: string, deviceType: string, month?: string) {
    let query = `WHERE appId = '${appid}' AND os = '${os}' AND countryCode = '${region}'  AND deviceType = '${deviceType}'`;
    if (month && month.length > 0) {
      query += ` AND month = '${month}'`;
    }
    const urlString = `${this.getRatingsBaseUrl()}/getMobileAppChangeLog?q=${query}`;
    return this.getChangeLog(urlString);
  }

  getDomainChangelog(domain: string, region: string, device: string, month: string) {
    const api = 'overview';
    let query = '';
    if (device !== '') {
      query += ` AND deviceType = '${device}' `;
    }
    if (month && month !== '' && month !== 'last30Days') {
      query += ` AND month = '${month}' `;
    }
    const urlString = `${this.getRatingsBaseUrl()}/getDomainChangeLog?q=WHERE adDomain='${domain}' AND countryCode='${region}' ${query}`;
    return this.getChangeLog(urlString);
  }

  getCtvChangeLog(appid: string, device: string, region: string, includeSpoofing: boolean, month: string) {
    let query = `WHERE appId = '${appid}' AND device = '${device}' AND region = '${region}'`;
    if (month && month.length > 0) {
      query += ` AND month = '${month}'`;
    }
    const urlString = `${this.getRatingsBaseUrl('2018')}/getCtvAppChangeLog?q=${query}&includeSpoofing=${includeSpoofing}`;
    return this.getChangeLog(urlString);
  }

  getChangeLog(urlString: string) {
    return new Promise((resolve) => {
      if (this.appChangeLogCache[urlString]) {
        const cacheData: ChangeLogResponse = JSON.parse(JSON.stringify(this.appChangeLogCache[urlString]));
        resolve(cacheData);
      } else {
        this.http.get(urlString)
          .subscribe((res) => {
            this.storeCache(res, this.appChangeLogCache, urlString);
            resolve(res);
          });
      }
    });
  }

  toJsonObject(data: string) {
    return JSON.parse(JSON.stringify(data));
  }

  storeCache(res: any, dataCache: [string, ChangeLogResponse], urlString: any) {
    if (res['status'] && res['status'] === 401) {
      // Do Nothing
    } else {
      dataCache[urlString] = res;
    }
  }
}
