<div class="list-group" *ngIf="state?.items">
  <div *ngIf="searchable" class="ais-RefinementList-searchBox">
      <img class="search-icon" src="/assets/images/icon-search-keywords.svg" alt="Search Icon">
      <input [(ngModel)]="searchText" type="text" class="search" (ngModelChange)="searchTextChanged($event)">
      <button class="btn clear-btn" (click)="clearSearch()" *ngIf="searchText">x</button>
  </div>
  <a class="list-group-item list-group-item-action"
     [ngClass]="{ active: item.isRefined }"
     *ngFor="let item of state?.items"
     (click)="state.refine(item.value)">
    {{item.label | titlecase}}
    <i class="active-icon fa fa-check" *ngIf="item.isRefined"></i>
  </a>
</div>
