<article>
  <!-- Stats -->
  <div id="stats">
    <ais-stats>
      <ng-template let-state="state" let-totalResults="state.nbHits" let-hitsPerPage="state.hitsPerPage"
        let-page="state.page">

        <div class="d-flex flex-column px-3 px-lg-0">
          <div class="d-none d-md-flex flex-column flex-md-row align-items-center mb-4">
            <span class="mt-2 mr-2 align-self-start">Filters:</span>
            <app-current-refinements></app-current-refinements>
          </div>

          <div class="d-flex flex-column flex-md-row">
            <div class="d-flex align-items-center justify-content-between result-text">
              <span>
                <ng-container *ngIf="(totalResults > 0) else noResults">
                  {{totalResults | number}} Result<ng-container *ngIf="totalResults > 1">s</ng-container>
                </ng-container>
                <ng-template #noResults>
                  No results
                </ng-template>
              </span>
              <ng-container *ngIf="( mobile$ | async )">
                <button class="btn btn-link btn-sm ml-3" (click)="mobileFilterMenuClick.emit($event)">
                  <img src="/assets/images/icon-filter.svg" alt="Toggle Side Nav">
                </button>
              </ng-container>
            </div>

            <div *ngIf="totalResults > 0" class="d-none d-lg-flex justify-content-center result-controls">
              <ng-container *ngIf="!( mobile$ | async )">
                <button type="button" class="btn btn-link p-0" (click)="viewTypeClick('tile')">
                  <img src="/assets/images/kcs/kcs_grid{{ viewType === 'tile' ? '_active' : '' }}.svg" />
                </button>
                <button type="button" class="btn btn-link p-0" (click)="viewTypeClick('list')">
                  <img src="/assets/images/kcs/kcs_search{{ viewType === 'list' ? '_active' : '' }}.svg" />
                </button>
              </ng-container>
              <div class="d-inline ml-2">
                <div *ngIf="downloading" class="d-inline-flex align-items-center">
                  <span>Downloading</span>
                  <div class="loading-icon download-spinner" data-loader="circle-side"></div>
                </div>
                <div class="btn-group kcs-btn-group" *ngIf="!downloading">
                  <button type="button" class="btn btn-secondary" (click)="download('json')" title="Download as JSON">
                    <img class="download-link" src="/assets/images/kcs/kcs_download.svg" />
                    <ng-container *ngIf="!( sm$ | async )">Download as JSON</ng-container>
                  </button>
                  <button type="button" class="btn btn-secondary dropdown-toggle px-2" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    <img src="/assets/images/kcs/kcs_chevron_down.svg" />
                    <span class="sr-only">Toggle Dropdown</span>
                  </button>
                  <div class="dropdown-menu" [class.dropdown-menu-right]="( mobile$ | async )">
                    <button class="dropdown-item" (click)="download('json')">Download as JSON</button>
                    <button class="dropdown-item" (click)="download('csv')">Download as CSV</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </ais-stats>
  </div>

  <!-- List or View -->
  <ais-infinite-hits [transformItems]="transformHits" #infinite>
    <ng-template let-hits="hits" let-results="results" let-refineNext="showMore" let-lastPage="isLastPage">
      <ng-container
        *ngTemplateOutlet="resultTemplate;context:{hits: hits, viewType: ( breakpoint$ | async ) ? 'tile' : viewType, instantSearchInstance: instantSearchInstance}">
      </ng-container>
      <div *ngIf="!lastPage" appOnVisible (onVisible)="refineNext($event)">&nbsp;</div>
    </ng-template>
  </ais-infinite-hits>
</article>
