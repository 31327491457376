import { Component, Inject, forwardRef, Optional } from '@angular/core';
import {
  TypedBaseWidget,
  NgAisInstantSearch,
  NgAisIndex
} from 'angular-instantsearch';

import connectCurrentRefinements, {
  CurrentRefinementsWidgetDescription,
  CurrentRefinementsConnectorParams
} from 'instantsearch.js/es/connectors/current-refinements/connectCurrentRefinements';
import {
  CONTENT_FILTERS,
  Filter,
  FilterType,
  PRIVACY_FILTERS
} from '../../ALGOLIA_CONFIG';

@Component({
  selector: 'app-current-refinements',
  templateUrl: './current-refinements.component.html',
  styleUrls: ['./current-refinements.component.scss']
})
export class CurrentRefinementsComponent extends TypedBaseWidget<
  CurrentRefinementsWidgetDescription,
  CurrentRefinementsConnectorParams
> {
  public state: CurrentRefinementsWidgetDescription['renderState']; // Rendering options

  private attributeMap = {};
  public FILTERTYPE = FilterType;

  private getDate(value: string | number): string {
    const date = new Date(value);
    const yyyy = date.getFullYear().toString();
    let mm = (date.getMonth() + 1).toString();
    let dd = date.getDate().toString();
    if (mm.length < 2) {
      mm = '0' + mm;
    }
    if (dd.length < 2) {
      dd = '0' + dd;
    }
    return `${yyyy}-${mm}-${dd}`;
  }

  constructor(
    @Inject(forwardRef(() => NgAisIndex))
    @Optional()
    public parentIndex: NgAisIndex,
    @Inject(forwardRef(() => NgAisInstantSearch))
    public instantSearchInstance: NgAisInstantSearch
  ) {
    super('CurrentRefinements');
  }

  ngOnInit() {
    this.createWidget(connectCurrentRefinements, {
      // instance options
      transformItems: items => {
        const newItems = items.map(item => {
          const filter: Filter = this.attributeMap[item.attribute];
          let refinements = item.refinements;
          let value = '';
          if (filter.type == FilterType.NumericMenuSelect) {
            value = `0 - ${refinements[0].value}`;
          } else if (filter.type == FilterType.DateRange) {
            value = `${this.getDate(refinements[0].value)} - ${this.getDate(
              refinements[1].value
            )}`;
          } else if (filter.type == FilterType.Menu) {
            value = item.refinements[0].label == 'true' ? 'Yes' : 'No';
          } else {
            refinements = item.refinements.map(ref => {
              return {
                ...ref,
                customLabel: `${filter.name}: ${ref.label}`
              };
            });
          }
          return {
            ...item,
            type: filter.type,
            refinements,
            customLabel: `${filter.name}: ${value}`
          };
        });
        return newItems;
      }
    });
    super.ngOnInit();

    CONTENT_FILTERS.concat(PRIVACY_FILTERS).forEach(filter => {
      if (filter.name) {
        this.attributeMap[filter.attribute] = filter;
      }
      if (filter.subCategories) {
        filter.subCategories.forEach(subFilter => {
          this.attributeMap[subFilter.attribute] = subFilter;
        });
      }
    });
  }

  public customRefine(refinements: any[]) {
    refinements.forEach(refinement => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      this.state.refine(refinement);
    });
  }
}
