import { Component } from '@angular/core';

@Component({
  selector: 'app-new-page-not-found',
  templateUrl: './page-not-found.component.html'
})
export class PageNotFoundComponent {

  constructor() {}

}
