import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

declare var jQuery: any;
export interface ParamMap {
    [key: string]: string;
}

@Component({
    selector: 'app-new-app-details-legacy',
    templateUrl: './app-details-legacy.component.html'
})
export class AppDetailsLegacyComponent implements OnInit {

    constructor(private _route: ActivatedRoute,
        private _router: Router) {
    }

    ngOnInit(): void {

        let appid = this._route.snapshot.params['appid'];
        let region = this._route.snapshot.params['region'];
        let os = this._route.snapshot.params['os'];
        let device = this._route.snapshot.params['device'];

        //https://ratings2.pixalate.com/app-details/284035177/NA/ios/smartphone
        //http://localhost:4200/insight/apps/284035177/NA/ios/smartphone

        if(appid && os && region && device) {
            this._router.navigate(['/insight/apps', appid,region,os,device], {replaceUrl: true});
        }
    }
}
