import {
  Component,
  Inject,
  forwardRef,
  Optional,
  Input,
  Output
} from '@angular/core';
import {
  TypedBaseWidget,
  NgAisInstantSearch,
  NgAisIndex
} from 'angular-instantsearch';

import connectRefinementList, {
  RefinementListWidgetDescription,
  RefinementListConnectorParams,
  RefinementListItem
} from 'instantsearch.js/es/connectors/refinement-list/connectRefinementList';
import { YesNoMapping } from '../../ALGOLIA_CONFIG';
import { RefinementListService } from './refinement-list.service';
import { debounceTime, filter, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-kcs-refinement-list',
  templateUrl: './keyword-refinement-list.component.html',
  styleUrls: ['./keyword-refinement-list.component.scss']
})
export class KeywordRefinementListComponent extends TypedBaseWidget<
  RefinementListWidgetDescription,
  RefinementListConnectorParams
> {
  @Input()
  attribute: string;

  @Input()
  yesno = false;

  @Input()
  yesnoMapping: YesNoMapping | undefined;

  @Input()
  searchable = false;

  searchText: string;
  searchTextModelChanged = new Subject<string>();

  public state: RefinementListWidgetDescription['renderState']; // Rendering options
  constructor(
    private refinementListService: RefinementListService,
    @Inject(forwardRef(() => NgAisIndex))
    @Optional()
    public parentIndex: NgAisIndex,
    @Inject(forwardRef(() => NgAisInstantSearch))
    public instantSearchInstance: NgAisInstantSearch
  ) {
    super('RefinementList');
  }
  // tslint:disable-next-line:use-lifecycle-interface
  ngOnInit() {
    this.createWidget(connectRefinementList, {
      // instance options
      attribute: this.attribute,
      sortBy: (item1: any, item2: any) => {
        if (this.yesno) {
          if (item1.name === item2.name) return 0;
          return item1.name === 'false' ? 1 : -1;
        } else {
          return item1.name.localeCompare(item2.name);
        }
      },
      transformItems: items => {
        if (this.yesno && items) {
          const yesLabel = this.yesnoMapping ? this.yesnoMapping.yes : 'Yes';
          const noLabel = this.yesnoMapping ? this.yesnoMapping.no : 'No';
          const newitems = [
            {
              count: 0,
              isRefined: false,
              label: yesLabel,
              value: 'true',
              highlighted: 'true'
            },
            {
              count: 0,
              isRefined: false,
              label: noLabel,
              value: 'false',
              highlighted: 'true'
            }
          ];
          newitems.forEach(nitem => {
            const fitem = items.filter(item => item.value === nitem.value)[0];
            if (fitem) {
              nitem.count = fitem.count;
              nitem.isRefined = fitem.isRefined;
              nitem.highlighted = fitem.highlighted;
            }
          });
          return newitems;
        }
        return items;
      }
    });
    this.searchTextModelChanged.pipe(debounceTime(300)).subscribe(() => {
      this.state.searchForItems(this.searchText);
    });

    this.refinementListService.refine$
      .pipe(filter(d => d.attribute === this.attribute))
      .subscribe(detail => {
        this.state.refine(detail.value);
      });

    super.ngOnInit();
  }

  clearSearch() {
    this.searchText = null;
    this.state.searchForItems('');
  }

  searchTextChanged() {
    this.searchTextModelChanged.next();
  }
}
