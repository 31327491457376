export interface PreferenceOption {
  id: string;
  label: string;
  permissions?: Array<string>;
  hidden?: boolean;
}

export interface PageOption {
  id: string;
  label: string;
  params: Array<PageOptionParam>;
  permissions?: Array<string>;
  hidden?: boolean;
}

export interface PageOptionParam {
  id: string;
  label: string;
  selected: PreferenceOption;
  options: Array<PreferenceOption>;
}

export const DEFAULT_LANDING_PAGE_LS: string = 'px-userpref-landpage';
export const DEFAULT_PAGE_OPTION_LS_PREFIX: string = 'px-userpref-pageoption-';

export const DEFAULT_LANDING_PAGE: PreferenceOption = {
  id: 'rankings',
  label: 'Rankings'
};

export const LANDING_PAGE_OPTIONS: Array<PreferenceOption> = [
  {
    id: 'rankings',
    label: 'Rankings',
    permissions: [
      'mrtSellerTrustIndex',
      'mrtMobileSellerTrustIndex',
      'mrtVideoSellerTrustIndex',
      'mrtDomainQualityIndex',
      'mrtMobileAppRanking',
      'mrtDomainAdsTxtIndex',
      'mrtAppAdsTxtIndex'
    ]
  },
  {
    id: 'discovery',
    label: 'Discovery',
    permissions: ['mrtAppDiscovery', 'mrtSearch', 'mrtOTTDiscovery']
  }
];

export const PAGE_OPTIONS: Array<PageOption> = [
  {
    id: 'rankings-type',
    label: 'Rankings',
    permissions: [
      'mrtDomainQualityRankings',
      'mrtDomainShareOfVoiceRankings',
      'mrtDomainPublisherQualityRankings',
      'mrtDomainPublisherShareOfVoiceRankings',
      'mrtDomainDeviceQualityRankings',
      'mrtDomainDeviceShareOfVoiceRankings',
      'mrtAppSellerQualityRankings',
      'mrtAppSellerShareOfVoiceRankings',
      'mrtAppPublisherQualityRankings',
      'mrtAppPublisherShareOfVoiceRankings',
      'mrtAppDeviceQualityRankings',
      'mrtAppDeviceShareOfVoiceRankings',
      'mrtCtvSellerQualityRankings',
      'mrtCtvShareOfVoiceRankings',
      'mrtCtvPublisherQualityRankings',
      'mrtCtvPublisherShareOfVoiceRankings',
      'mrtCtvDeviceQualityRankings',
      'mrtCtvDeviceShareOfVoiceRankings'
    ],
    params: [
      {
        id: 'rankingtype',
        label: 'Ranking Type',
        selected: {
          id: 'gsti',
          label: 'Global Seller Trust Index'
        },
        options: [
          {
            id: 'gsti',
            label: 'Global Seller Trust Index',
            permissions: ['mrtSellerTrustIndex']
          },
          {
            id: 'msti',
            label: 'Mobile Seller Trust Index',
            permissions: ['mrtMobileSellerTrustIndex']
          },
          {
            id: 'vsti',
            label: 'Video Seller Trust Index',
            permissions: ['mrtVideoSellerTrustIndex']
          },
          {
            id: 'web-ads-txt',
            label: 'Ads.txt',
            permissions: ['mrtDomainAdsTxtIndex']
          },
          {
            id: 'app-ads-txt',
            label: 'AppAds.txt',
            permissions: ['mrtAppAdsTxtIndex']
          },
          {
            id: 'dqi',
            label: 'Domain Quality Index',
            permissions: ['mrtDomainQualityIndex']
          },
          {
            id: 'aqi',
            label: 'App Quality Index',
            permissions: ['mrtMobileAppRanking']
          }
        ]
      }
    ]
  },
  {
    id: 'discovery',
    label: 'Discovery',
    permissions: ['mrtAppDiscovery', 'mrtSearch', 'mrtOTTDiscovery'],
    params: [
      {
        id: 'discoverytype',
        label: 'Discovery Type',
        selected: {
          id: 'apps',
          label: 'Apps'
        },
        options: [
          {
            id: 'apps',
            label: 'Apps',
            permissions: ['mrtAppDiscovery']
          },
          {
            id: 'domains',
            label: 'Domains',
            permissions: ['mrtSearch']
          },
          {
            id: 'ctv',
            label: 'CTV',
            permissions: ['mrtOTTDiscovery']
          }
        ]
      }
    ]
  },
  {
    id: 'discovery-domains',
    label: 'Discovery - Domains',
    permissions: ['mrtSearch'],
    params: [
      {
        id: 'device',
        label: 'Device',
        selected: {
          id: 'desktop',
          label: 'Desktop'
        },
        options: [
          {
            id: 'GLOBAL',
            label: 'All Devices'
          },
          {
            id: 'desktop',
            label: 'Desktop'
          },
          {
            id: 'mobile',
            label: 'Mobile'
          }
        ]
      },
      {
        id: 'region',
        label: 'Region',
        selected: {
          id: 'GLOBAL',
          label: 'GLOBAL'
        },
        options: [
          {
            id: 'US',
            label: 'US'
          },
          {
            id: 'NONUS',
            label: 'Non U.S.'
          },
          {
            id: 'GLOBAL',
            label: 'GLOBAL'
          }
        ]
      },
      {
        id: 'subdomains',
        label: 'Subdomains',
        selected: {
          id: 'F',
          label: 'Exclude'
        },
        options: [
          {
            id: 'F',
            label: 'Exclude'
          },
          {
            id: 'T',
            label: 'Include'
          }
        ]
      }
    ]
  },
  {
    id: 'discovery-apps',
    label: 'Discovery - Apps',
    permissions: ['mrtAppDiscovery'],
    params: [
      {
        id: 'store',
        label: 'Store',
        selected: {
          id: 'ios',
          label: 'App Store'
        },
        options: [
          {
            id: 'ios',
            label: 'App Store'
          },
          {
            id: 'android',
            label: 'Google Play'
          }
        ]
      },
      {
        id: 'device',
        label: 'Device',
        selected: {
          id: 'all',
          label: 'Smartphone and Tablet'
        },
        options: [
          {
            id: 'GLOBAL',
            label: 'All Devices'
          },
          {
            id: 'all',
            label: 'Smartphone and Tablet'
          },
          {
            id: 'smartphone',
            label: 'Smartphone'
          },
          {
            id: 'tablet',
            label: 'Tablet'
          }
        ]
      },
      {
        id: 'region',
        label: 'Region',
        selected: {
          id: 'GLOBAL',
          label: 'GLOBAL'
        },
        options: [
          {
            id: 'NA',
            label: 'North America'
          },
          {
            id: 'EMEA',
            label: 'EMEA'
          },
          {
            id: 'LATAM',
            label: 'LATAM'
          },
          {
            id: 'APAC',
            label: 'APAC'
          },
          {
            id: 'GLOBAL',
            label: 'GLOBAL'
          }
        ]
      }
    ]
  },
  {
    id: 'discovery-ctv',
    label: 'Discovery - CTV',
    permissions: ['mrtOTTDiscovery'],
    params: [
      {
        id: 'device',
        label: 'Device',
        selected: {
          id: 'roku',
          label: 'Roku'
        },
        options: [
          {
            id: 'roku',
            label: 'Roku'
          },
          {
            id: 'firetv',
            label: 'Fire TV'
          },
          {
            id: 'samsung',
            label: 'Samsung'
          },
          {
            id: 'tvos',
            label: 'tvOS'
          }
        ]
      },
      {
        id: 'region',
        label: 'Region',
        selected: {
          id: 'GLOBAL',
          label: 'GLOBAL'
        },
        options: [
          {
            id: 'NA',
            label: 'North America'
          },
          {
            id: 'GLOBAL',
            label: 'GLOBAL'
          }
        ]
      },
      {
        id: 'brand',
        label: 'Brand',
        selected: {
          id: 'GLOBAL',
          label: 'GLOBAL'
        },
        options: [
          {
            id: 'GLOBAL',
            label: 'GLOBAL'
          }
        ]
      }
    ]
  }
];
