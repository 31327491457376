import { Injectable } from '@angular/core';
import { fromEvent, merge, Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

export enum Breakpoint {
  xs = 0,
  sm = 1,
  md = 2,
  lg = 3,
  xl = 4
}

interface MediaQueryEvent {
  breakpoint: Breakpoint;
  isOn: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class MediaQueryService {
  public activeBreakpoint$: ReplaySubject<Breakpoint>;

  private breakpointMap: Map<Breakpoint, string> = new Map([
    [Breakpoint.xs, ''],
    [Breakpoint.sm, '(min-width: 675.01px)'],
    [Breakpoint.md, '(min-width: 768.01px)'],
    [Breakpoint.lg, '(min-width: 992.01px)'],
    [Breakpoint.xl, '(min-width: 1200.01px)']
  ]);

  private breakpointStatusMap: boolean[] = [];

  constructor() {
    this.activeBreakpoint$ = new ReplaySubject<Breakpoint>(1);

    this.initializeBreakpoints();
  }

  public initializeBreakpoints() {
    const evts: Observable<MediaQueryEvent>[] = [];

    for (const [k, v] of this.breakpointMap) {
      const query = window.matchMedia(v);

      this.breakpointStatusMap[k] = query.matches;

      evts.push(
        fromEvent<MediaQueryListEvent>(query, 'change').pipe(
          map(evt => {
            return {
              breakpoint: k,
              isOn: evt.matches
            } as MediaQueryEvent;
          })
        )
      );
    }

    this._updateActiveBreakpoint();

    merge(...evts).subscribe(evt => {
      this.breakpointStatusMap[evt.breakpoint] = evt.isOn;

      this._updateActiveBreakpoint();
    });
  }

  private _updateActiveBreakpoint() {
    for (let i = this.breakpointStatusMap.length - 1; i >= 0; i--) {
      if (this.breakpointStatusMap[i] === true) {
        this.activeBreakpoint$.next(i);
        break;
      }
    }
  }

  public query(...breakpoints: Breakpoint[]): Observable<boolean> {
    return this.activeBreakpoint$.pipe(map(b => breakpoints.includes(b)));
  }

  public lte(breakpoint: Breakpoint): Observable<boolean> {
    return this.activeBreakpoint$.pipe(map(b => b <= breakpoint));
  }

  public lt(breakpoint: Breakpoint): Observable<boolean> {
    return this.activeBreakpoint$.pipe(map(b => b < breakpoint));
  }

  public gte(breakpoint: Breakpoint): Observable<boolean> {
    return this.activeBreakpoint$.pipe(map(b => b >= breakpoint));
  }

  public gt(breakpoint: Breakpoint): Observable<boolean> {
    return this.activeBreakpoint$.pipe(map(b => b > breakpoint));
  }
}
