import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stripNlnl'
})
export class StripNlnlPipe implements PipeTransform {
  constructor() {}

  transform(value: string): unknown {
    return value.replace(/NLNL/g, '\n').replace(/CRCR/g, '\r');
  }
}
