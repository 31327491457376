<app-content-keyword-contextual-search *ngIf="( searchEvents | async )?.type === 'content'" [scrollContainer]="element"
  [injectSearchQuery]="searchQueryInjector" (close)="closeButtonClick()" [isOverlay]="isOverlay">
</app-content-keyword-contextual-search>
<app-pp-keyword-contextual-search *ngIf="( searchEvents | async )?.type === 'privacy-policy'"
  [scrollContainer]="element" [injectSearchQuery]="searchQueryInjector" (close)="closeButtonClick()"
  [isOverlay]="isOverlay">
</app-pp-keyword-contextual-search>
<app-kcs-title-search *ngIf="( searchEvents | async )?.type === 'title'"
                                  [scrollContainer]="element" [injectSearchQuery]="searchQueryInjector" (close)="closeButtonClick()"
                                  [isOverlay]="isOverlay">
</app-kcs-title-search>
