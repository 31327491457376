import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'app/models/User';
import { DEFAULT_LANDING_PAGE_LS, PreferenceOption } from '../userprofile/preferences/preferences.config';

@Component({
    selector: 'app-landing-page-routing',
    templateUrl: './landing-page-routing.component.html'
})
export class LandingPageRoutingComponent implements OnInit {

  constructor(private _router: Router,
              private _user: User) {}

  ngOnInit(): void {
    let landingPage = localStorage.getItem(DEFAULT_LANDING_PAGE_LS);
    if (this._user.isAnonymous() || !landingPage) {
      this._router.navigate(['/rankings'], {replaceUrl: true});
    }
    else {
      let landingPageObj:PreferenceOption = JSON.parse(landingPage);
      this._router.navigate(['/', landingPageObj.id], {replaceUrl: true});
    }
  }
}
