import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DEFAULT_LANDING_PAGE_LS, PreferenceOption, PAGE_OPTIONS, DEFAULT_PAGE_OPTION_LS_PREFIX } from '../userprofile/preferences/preferences.config';
import {User} from '../../../models/User';
import {EventBusService} from '../../../services/EventBusService';

declare var jQuery: any;

@Component({
    selector: 'app-discovery-page-routing',
    templateUrl: './discovery-page-routing.component.html'
})
export class DiscoveryPageRoutingComponent implements OnInit {

    constructor(private _route: ActivatedRoute,
                private _router: Router,
                private _user: User,
                private _eventBus: EventBusService) {
    }

    ngOnInit(): void {
      console.log(this._router.url);
      const showFollowing = this._router.url.indexOf('watchlist') >= 0 || this._router.url.indexOf('showFollowing') >= 0;
      const queryParams = {};
      if (showFollowing) {
        queryParams['showFollowing'] = 'true';
      }
      let currentInsightKey = this._user.getCurrentInsightsKey();
      if (!currentInsightKey || currentInsightKey === 'null') {
        const paramId = PAGE_OPTIONS.filter((pageOption) => pageOption.id === 'discovery')[0].params[0].id;
        const pagePrefOption = localStorage.getItem(`${DEFAULT_PAGE_OPTION_LS_PREFIX}discovery-${paramId}`);
        if (pagePrefOption) {
          const pagePrefOptionObj: PreferenceOption = JSON.parse(pagePrefOption);
          currentInsightKey = pagePrefOptionObj.id;
        }
      }
      if (currentInsightKey && currentInsightKey !== 'null') {
        if (currentInsightKey === 'apps') {
          // Apps Discovery
          // /discovery/apps/ios/GLOBAL/GLOBAL

          const appParamMap = {
            store: 'ios',
            device: 'GLOBAL',
            region: 'GLOBAL'
          };

          const params = PAGE_OPTIONS.filter((pageOption) => pageOption.id === 'discovery-apps')[0].params;
          params.forEach((param) => {
            const pagePrefOption = localStorage.getItem(`${DEFAULT_PAGE_OPTION_LS_PREFIX}discovery-apps-${param.id}`);
            if (pagePrefOption) {
              const pagePrefOptionObj: PreferenceOption = JSON.parse(pagePrefOption);
              appParamMap[param.id] = pagePrefOptionObj.id;
            }
          });
          this._router.navigate([
            `/discovery/apps/${showFollowing ? 'list' : 'dashboard'}`,
            showFollowing ? 'all' : appParamMap['store'],
            showFollowing ? 'GLOBAL' : appParamMap['device'],
            showFollowing ? 'GLOBAL' : appParamMap['region']],
            {replaceUrl: true, queryParams: queryParams});
        } else if (currentInsightKey === 'domains') {
          // Domains Discovery
          // /discovery/domains/desktop/GLOBAL/F
          // device, region, subdomains
          const domainParamMap = {
            device: 'desktop',
            region: 'GLOBAL',
            subdomains: 'F'
          };

          const params = PAGE_OPTIONS.filter((pageOption) => pageOption.id === 'discovery-domains')[0].params;
          params.forEach((param) => {
            const pagePrefOption = localStorage.getItem(`${DEFAULT_PAGE_OPTION_LS_PREFIX}discovery-domains-${param.id}`);
            if (pagePrefOption) {
              const pagePrefOptionObj: PreferenceOption = JSON.parse(pagePrefOption);
              domainParamMap[param.id] = pagePrefOptionObj.id;
            }
          });
          this._router.navigate([
            `/discovery/domains/${showFollowing ? 'list' : 'dashboard'}`,
            showFollowing ? 'GLOBAL' : domainParamMap['device'],
            showFollowing ? 'GLOBAL' : domainParamMap['region'],
            showFollowing ? 'T' : domainParamMap['subdomains']],
            {replaceUrl: true, queryParams: queryParams});
        } else if (currentInsightKey === 'ctv') {
          // Domains Discovery
          // /discovery/domains/desktop/GLOBAL/F
          // device, region, subdomains
          const domainParamMap = {
            device: 'roku',
            region: 'GLOBAL'
          };

          const params = PAGE_OPTIONS.filter((pageOption) => pageOption.id === 'discovery-ctv')[0].params;
          params.forEach((param) => {
            const pagePrefOption = localStorage.getItem(`${DEFAULT_PAGE_OPTION_LS_PREFIX}discovery-ctv-${param.id}`);
            if (pagePrefOption) {
              const pagePrefOptionObj: PreferenceOption = JSON.parse(pagePrefOption);
              domainParamMap[param.id] = pagePrefOptionObj.id;
            }
          });

          this._router.navigate([
            `/discovery/ctv/${showFollowing ? 'list' : 'dashboard'}`,
            showFollowing ? 'all' : domainParamMap['device'],
            showFollowing ? 'GLOBAL' : domainParamMap['region'],
            'GLOBAL'],
            {replaceUrl: true, queryParams: queryParams});
        }
      } else {

        if (this._user.appDiscoveryEnabled()) {
          this._router.navigate(['/discovery/apps'], {replaceUrl: true, queryParams: queryParams});
        } else if (this._user.domainSearchEnabled()) {
          this._router.navigate(['/discovery/domains'], {replaceUrl: true, queryParams: queryParams});
        } else if (this._user.ottDiscoveryEnabled()) {
          this._router.navigate(['/discovery/ctv'], {replaceUrl: true, queryParams: queryParams});
        } else if (this._user.isFreemium()) {
          this._router.navigate(['/discovery/freemium'], {replaceUrl: true, queryParams: queryParams});
        } else {
          // Do Nothing
          console.log('NOTHING TO ROUTE!!!');
          this._eventBus.showPopupEvent.emit(true);
        }
      }
    }
}
