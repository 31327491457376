import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { routing } from './data-table.routing';
import { PipesModule } from '../../pipes/pipes.module';
import { DataTableComponent } from './data-table.component';
import { DirectivesModule } from '../../directives/directives.module';
import { TooltipModule } from '../tooltip/tooltip.module';
import { DataTableRowComponent } from './data-table-row/data-table-row.component';
import { SwiperModule } from 'swiper/angular';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [DataTableComponent, DataTableRowComponent],
  exports: [DataTableComponent, DataTableRowComponent],
  imports: [
    TooltipModule,
    PipesModule,
    CommonModule,
    DirectivesModule,
    SwiperModule,
    routing,
    SharedModule
  ]
})
export class DataTableModule {}
