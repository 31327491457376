import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  Breakpoint,
  MediaQueryService
} from 'app/services/media-query.service';
import { Observable } from 'rxjs';
import { Filter, FilterType } from '../ALGOLIA_CONFIG';
import { UiState } from 'instantsearch.js/es/types';

@Component({
  selector: 'app-kcs-side-filters',
  templateUrl: './kcs-side-filters.component.html',
  styleUrls: ['./kcs-side-filters.component.scss']
})
export class KcsSideFiltersComponent implements OnChanges {
  @HostBinding('class.is-overlay') @Input() isOverlay: boolean;
  @Input() filters: Filter[];

  @Input() primaryIndex: string;
  @Input() currentUiState: UiState;

  @HostBinding('class.open') @Input() showMobile: boolean;

  @Output() navCloseClick: EventEmitter<MouseEvent> =
    new EventEmitter<MouseEvent>();

  FILTER_TYPES = FilterType;

  showPrimaryCategories = false;
  showSubCategories = false;

  mobile$: Observable<boolean>;

  constructor(
    mediaQuery: MediaQueryService,
    private element: ElementRef<HTMLElement>
  ) {
    this.mobile$ = mediaQuery.lte(Breakpoint.md);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showMobile) {
      if (changes.showMobile.currentValue) {
        this.element.nativeElement.addEventListener(
          'click',
          this._onSidebarClick
        );
      } else {
        this.element.nativeElement.removeEventListener(
          'click',
          this._onSidebarClick
        );
      }
    }
  }

  hasSelectedFilters(attribute: string) {
    const index = this.currentUiState[this.primaryIndex];
    if (index) {
      const refinementList = index.refinementList;
      if (refinementList && refinementList[attribute]) {
        return true;
      }
    }

    return false;
  }

  _onSidebarClick = (evt: MouseEvent) => {
    const el = this.element.nativeElement;

    const rect = el.getBoundingClientRect();

    if (evt.clientX > rect.left && evt.clientX < rect.right) {
      return;
    }

    this.navCloseClick.emit(evt);

    evt.preventDefault();
  };
}
