export const ALGOLIA_APP_ID = 'AWY63WPYLF';
export const ALGOLIA_API_KEY = '471f4e22aa833a11ef213cd30c540344';
export const ALGOLIA_INDEX = 'prod_v2_apps';

export enum SEARCH_CONTEXT {
  CONTENT,
  PRIVACY,
  SIMPLE_SEARCH
}

export interface YesNoMapping {
  yes: string;
  no: string;
}

export enum FilterType {
  DateRange = 'DateRange',
  NumericMenuSelect = 'NumericMenuSelect',
  RefinementList = 'RefinementList',
  Menu = 'Menu'
}
export interface Filter {
  name: string;
  type?: FilterType;
  attribute?: string;
  yesno?: boolean;
  items?: any[];
  subCategories?: Filter[];
  key?: string;
  urlKey?: string;
  showSubCat?: boolean;
  yesnoMapping?: YesNoMapping;
  searchable?: boolean;
}

export const CONTENT_FILTERS: Filter[] = [
  {
    name: 'COPPA Audience',
    type: FilterType.Menu,
    attribute: 'coppaTargetChildren',
    yesno: true,
    yesnoMapping: {
      yes: 'Child Directed',
      no: 'General Audience'
    }
  },
  {
    name: 'Privacy Policy Detected',
    type: FilterType.Menu,
    attribute: 'hasPrivacyLink',
    yesno: true
  },
  {
    name: 'Developer Country',
    type: FilterType.RefinementList,
    attribute: 'developerCountryName',
    searchable: true
  },
  {
    name: 'Categories',
    subCategories: [
      {
        name: 'Store Categories',
        type: FilterType.RefinementList,
        attribute: 'appStorePrimaryCategories',
        key: 'app-store-primary-category',
        searchable: true
      },
      {
        name: 'Store Subcategories',
        type: FilterType.RefinementList,
        attribute: 'appStoreSecondaryCategories',
        key: 'app-store-subcategory',
        searchable: true
      },
      {
        name: 'IAB Categories',
        type: FilterType.RefinementList,
        attribute: 'iabPrimaryCategory',
        key: 'primary-category',
        searchable: true
      },
      {
        name: 'IAB Subcategories',
        type: FilterType.RefinementList,
        attribute: 'iabSubCategory',
        key: 'sub-category',
        searchable: true
      }
    ]
  },
  {
    name: 'App-ads.txt Detected',
    type: FilterType.Menu,
    attribute: 'hasAppAdsTxt',
    yesno: true
  },
  {
    name: 'Ads Detected',
    type: FilterType.Menu,
    attribute: 'hasAds',
    yesno: true
  },
  {
    name: 'Estimated Daily Reach',
    type: FilterType.NumericMenuSelect,
    attribute: 'dailyActiveUsers',
    items: [
      { label: 'All' },
      { end: 100, label: '0 - 100' },
      { end: 1000, label: '0 - 1K' },
      { end: 10000, label: '0 - 10K' },
      { end: 1000000, label: '0 - 1M' }
    ]
  },
  {
    name: 'Estimated Monthly Reach',
    type: FilterType.NumericMenuSelect,
    attribute: 'monthlyActiveUsers',
    items: [
      { label: 'All' },
      { end: 100, label: '0 - 100' },
      { end: 1000, label: '0 - 1K' },
      { end: 10000, label: '0 - 10K' },
      { end: 1000000, label: '0 - 1M' }
    ]
  },
  {
    name: 'App Release Date',
    type: FilterType.DateRange,
    attribute: 'releaseDate'
  },
  {
    name: "App's Last Update Date",
    type: FilterType.DateRange,
    attribute: 'lastUpdatedDate'
  },
  {
    name: 'Delisted Date',
    type: FilterType.DateRange,
    attribute: 'delistedDate'
  }
];

export const PRIVACY_FILTERS: Filter[] = [
  {
    name: 'Language',
    type: FilterType.RefinementList,
    attribute: 'pp_language'
  },
  {
    name: 'App Store Category',
    type: FilterType.RefinementList,
    attribute: 'appStorePrimaryCategories',
    urlKey: 'appStoreCategory'
  }
];
