import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  OnChanges
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

export interface PillFilter {
  name: string;
  key: string;
}

@Component({
  selector: 'app-kcs-pill-filters',
  templateUrl: './kcs-pill-filters.component.html',
  styleUrls: ['./kcs-pill-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KcsPillFiltersComponent {
  pillFilters: PillFilter[] = [
    {
      name: 'All',
      key: 'all'
    },
    {
      name: 'CTV',
      key: 'allCtv'
    },
    {
      name: 'Mobile',
      key: 'allMobile'
    },
    {
      name: 'Roku',
      key: 'roku'
    },
    {
      name: 'Fire TV',
      key: 'firetv'
    },
    {
      name: 'Samsung',
      key: 'samsung'
    },
    {
      name: 'tvOS',
      key: 'tvos'
    },
    {
      name: 'Google Play',
      key: 'android'
    },
    {
      name: 'iOS App Store',
      key: 'ios'
    },
    {
      name: 'Domains',
      key: 'domain'
    }
  ];

  @Input()
  selectedFiltersArray: Array<string> = ['all'];

  @Input() filterKey: string;
  @Input() selectedFilterString: string;

  @Output()
  filterClick = new EventEmitter<string>();

  onFilterClick(pill: PillFilter) {
    this.filterClick.emit(pill.key);
  }
}
