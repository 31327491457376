import { Component, Inject, forwardRef, Optional, Input } from '@angular/core';
import {
  TypedBaseWidget,
  NgAisInstantSearch,
  NgAisIndex
} from 'angular-instantsearch';

import connectMenu, {
  MenuWidgetDescription,
  MenuConnectorParams
} from 'instantsearch.js/es/connectors/menu/connectMenu';
import { filter, tap } from 'rxjs/operators';
import { YesNoMapping } from '../../ALGOLIA_CONFIG';
import { RefinementListService } from '../refinement-list/refinement-list.service';

@Component({
  selector: 'app-kcs-menu',
  templateUrl: './kcs-menu.component.html',
  styleUrls: ['./kcs-menu.component.scss']
})
export class KcsMenuComponent extends TypedBaseWidget<
  MenuWidgetDescription,
  MenuConnectorParams
> {
  @Input()
  attribute: string;

  @Input()
  yesno = false;

  @Input()
  yesnoMapping: YesNoMapping | undefined;

  public state: MenuWidgetDescription['renderState']; // Rendering options
  constructor(
    private refinementListService: RefinementListService,
    @Inject(forwardRef(() => NgAisIndex))
    @Optional()
    public parentIndex: NgAisIndex,
    @Inject(forwardRef(() => NgAisInstantSearch))
    public instantSearchInstance: NgAisInstantSearch
  ) {
    super('Menu');
  }
  ngOnInit() {
    this.createWidget(connectMenu, {
      // instance options
      attribute: this.attribute,
      transformItems: items => {
        if (this.yesno && items) {
          const yesLabel = this.yesnoMapping ? this.yesnoMapping.yes : 'Yes';
          const noLabel = this.yesnoMapping ? this.yesnoMapping.no : 'No';
          const newitems = [
            {
              count: 0,
              isRefined: false,
              label: yesLabel,
              value: 'true',
              highlighted: 'true'
            },
            {
              count: 0,
              isRefined: false,
              label: noLabel,
              value: 'false',
              highlighted: 'true'
            }
          ];
          newitems.forEach(nitem => {
            const fitem = items.filter(item => item.value === nitem.value)[0];
            if (fitem) {
              nitem.count = fitem.count;
              nitem.isRefined = fitem.isRefined;
              // nitem.highlighted = fitem.highlighted;
            }
          });
          return newitems;
        }
        return items;
      }
    });

    this.refinementListService.refine$
      .pipe(
        tap(d => console.log(d, this.attribute)),
        filter(d => d.attribute === this.attribute)
      )
      .subscribe(detail => {
        this.state.refine(detail.value.toString());
      });

    super.ngOnInit();
  }
}
