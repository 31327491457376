import { Injectable } from '@angular/core';
import { TooltipData} from '../models/TooltipData';

@Injectable()
export class TooltipService {

    getItems() {
        return Promise.resolve(TooltipData);
    }

    getMap() {
        var tooltipMap = [];
        TooltipData.forEach(function(tooltip) {
            tooltipMap[tooltip.key] = tooltip.value;
        });
        return tooltipMap;
    }

    getTooltip(key: string) {
        return this.getItems()
            .then(alldata => alldata.filter(data => data.key === key)[0]);
    }
}