import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-kcs-domain-tile',
  templateUrl: './kcs-domain-tile.component.html',
  styleUrls: [
    './kcs-domain-tile.component.scss',
    '../../kcs-base/kcs-base-common.scss'
  ]
})
export class KcsDomainTileComponent implements OnInit {
  @Input()
  hit: any;

  @Input()
  indexName: string;

  @Input()
  isFreemium: boolean;

  @Input()
  viewType: string;

  constructor() {}

  ngOnInit(): void {}
}
