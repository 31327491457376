export const SHELL_STATE = {
  yellow : 'yellow',
  red: 'red',
  green: 'green'
};

export const SHELL_COUNTRIES = [
  {
    state: SHELL_STATE.yellow,
    countries: [
      'andorra',
      'anguilla',
      'antigua and barbuda',
      'aruba',
      'the bahamas',
      'bahrain',
      'bermuda',
      'belize',
      'british virgin islands',
      'cayman islands',
      'cook islands',
      'cyprus',
      'dominica',
      'gibraltar',
      'grenada',
      'guernsey',
      'isle of man',
      'jersey',
      'liberia',
      'liechtenstein',
      'malta',
      'marshall islands',
      'mauritius',
      'monaco',
      'montserrat',
      'nauru',
      'netherlands antilles',
      'niue',
      'panama',
      'samoa',
      'san marino',
      'seychelles',
      'st. lucia',
      'st. kitts & nevis',
      'st. vincent and the grenadines',
      'turks & caicos islands',
      'us virgin islands',
      'vanuatu',
    ]
  }
];


