import { Injectable, EventEmitter } from '@angular/core';
import { BaseApiService } from './base-api-service';
import { Router } from '@angular/router';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppNavigationService extends BaseApiService {
  onSetSelected: ReplaySubject<string> = new ReplaySubject<string>(2);
  onSetIsMrtSearch: ReplaySubject<string | undefined> = new ReplaySubject<
    string | undefined
  >();

  constructor(private router: Router) {
    super();
  }

  getScriptBaseUrl() {
    const env = this.getEnv();

    return `https://cdn.pixalate.com/app-navigation${
      env !== 'prod' ? `-${env}` : ''
    }${this.getFtEnv()}/`;
  }

  getScriptUrl() {
    return `${this.getScriptBaseUrl()}app-navigation.js`;
  }

  setSelected(navId: string) {
    this.onSetSelected.next(navId);
  }

  setIsMrtSearch(value: string | undefined) {
    this.onSetIsMrtSearch.next(value);
  }
}
