/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { BooleanMap, StringMap } from 'app/types/string-map';

/**
 * Generate array of string dto based on StringMap and setting (key, value) where value is boolean
 *
 * @param stringMap
 * @param setting
 * @returns string[]
 */
export function mapArraySettingsDTO(
  stringMap: StringMap,
  ...setting: BooleanMap[]
): string[] {
  const entries = [];
  for (let i = 0; i < setting.length; i++) {
    entries.push(...Object.entries(setting[i]));
  }
  return Object.values(entries)
    .filter(entry => entry[1])
    .map(entry => stringMap[entry[0]]);
}

/**
 * Generate settings object based on StringMap and array of strings (settings)
 *
 * @param stringMap
 * @param arr
 * @returns Settings Objects
 */
export function mapArrayDTOSettings(stringMap: StringMap, arr: string[] = []) {
  const settings = {};
  Object.keys(stringMap).forEach(key => {
    settings[key] = arr.includes(stringMap[key]);
  });
  return settings;
}
