<div class="result {{viewType}} {{indexName}}">

  <ng-container *ngIf="!isFreemium else freemiumPopover">
    <a [routerLink]="hit.routerLink" enTooltip [attr.title]="hit.adDomain">
      <img class="result-icon" src="https://www.google.com/s2/favicons?domain={{hit.adDomain}}&sz=48" />
    </a>

    <h3 class="result-title" *ngIf="hit.adDomain">
      <a [routerLink]="hit.routerLink" enTooltip [title]="hit.adDomain">
        <ais-highlight attribute="adDomain" [hit]="hit"></ais-highlight>
      </a>
    </h3>
  </ng-container>

  <ng-template #freemiumPopover>
    <a enTooltip [attr.title]="hit.adDomain" enPopover insertClass="popover-upgrade-required"
       data-container="body" data-toggle="popover" data-placement="top" data-tooltip-placement="top"
       data-content="Upgrade required.">
      <img class="result-icon" src="https://www.google.com/s2/favicons?domain={{hit.adDomain}}&sz=48" />
    </a>

    <h3 class="result-title" *ngIf="hit.adDomain">
      <a enTooltip [title]="hit.adDomain" enPopover insertClass="popover-upgrade-required"
         data-container="body" data-toggle="popover" data-placement="top" data-tooltip-placement="top"
         data-content="Upgrade required.">
        <ais-highlight attribute="adDomain" [hit]="hit"></ais-highlight>
      </a>
    </h3>
  </ng-template>

  <div class="result-body">
    <h3 class="result-title" *ngIf="hit.title">
      <a [routerLink]="hit.routerLink" enTooltip [title]="hit.title">
        <ais-highlight attribute="title" [hit]="hit"></ais-highlight>
      </a>
    </h3>

    <ul class="result-meta">
      <li class="result-meta-item">
        <div class="d-flex flex-column">
          <div>
            Category: <button mrt-attribute-btn [indexName]="indexName" attribute="iabPrimaryCategory"
                              [value]="hit.iabPrimaryCategory" class="btn btn-link result-meta-link">{{
            hit.iabPrimaryCategory }}</button>
          </div>
          <div>
            Sub-Category: <button mrt-attribute-btn [indexName]="indexName" attribute="iabSubCategory"
                                  [value]="hit.iabSubCategory" class="btn btn-link result-meta-link">{{
            hit.iabSubCategory }}</button>
          </div>
        </div>
      </li>
    </ul>
    <ul class="result-meta result-meta-body">
      <li class="result-meta-item" [ngClass]="{ invisible: !hit?.hasPrivacyLink }">
        <img height="16" src="/assets/images/icon-indicator-yes-2.svg" />
        <button mrt-attribute-btn attribute="hasPrivacyLink" [indexName]="indexName" [value]="hit.hasPrivacyLink"
                class="btn btn-link result-meta-link">Privacy Policy detected</button>
      </li>
    </ul>

  </div>

</div>
