import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RankingsAnonFormService {

  private localStorageKey: string = 'RANKINGS_ANON_EMAIL_SUBMITTED';
  private submittedValue: string = 'true';

  constructor() { }

  isFormSubmitted() {
    const value = sessionStorage.getItem(this.localStorageKey);
    return value === this.submittedValue;
  }

  submitForm() {
    sessionStorage.setItem(this.localStorageKey, this.submittedValue);
  }
}
