/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import { AppNavigationService } from '../../services/app-navigation.service';
import {
  ActivatedRouteSnapshot,
  NavigationStart,
  Router
} from '@angular/router';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { EventBusService } from 'app/services/EventBusService';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationSettingsComponent } from '../shared/notification-settings/notification-settings.component';

export type NavigationEventType =
  | 'search-query'
  | 'search-input-action'
  | 'route';

type RouteNavigationEvent = {
  eventType: 'route';
  route: string;
};

export interface InputActionEvent {
  focused: boolean;
  searchBegan?: boolean;
  searchCleared?: boolean;
  escapePressed?: boolean;
  enterPressed?: boolean;
}

export interface SearchQueryEvent {
  type: string;
  query: string | null;
}

type SearchInputActionNavigationEvent = {
  eventType: 'search-input-action';
  inputAction: InputActionEvent;
};

type SearchQueryNavigationEvent = {
  eventType: 'search-query';
  searchQuery: SearchQueryEvent;
};

type OpenGlobalSettingsEvent = {
  eventType: 'open-global-settings';
};
export type NavigationEvent =
  | RouteNavigationEvent
  | SearchInputActionNavigationEvent
  | SearchQueryNavigationEvent
  | OpenGlobalSettingsEvent;

@Component({
  selector: 'app-new-navigation',
  templateUrl: './new-navigation.component.html',
  styleUrls: ['./new-navigation.component.scss']
})
export class NewNavigationComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild('topnav', { static: true }) topnav: ElementRef<HTMLDivElement>;
  unlistener: () => void;

  overlaySearchState: BehaviorSubject<boolean> = new BehaviorSubject(false);
  searchEvents: ReplaySubject<SearchQueryEvent> = new ReplaySubject(1);

  constructor(
    private appNavService: AppNavigationService,
    private renderer: Renderer2,
    private router: Router,
    private _eventBus: EventBusService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.overlaySearchState.subscribe(state => {
      const html = document.querySelector('html');

      if (state) {
        html.classList.add('no-scroll');
      } else {
        html.classList.remove('no-scroll');
      }
    });

    this.router.events.subscribe(evt => {
      // close the overlay whenever we navigate
      if (evt instanceof NavigationStart) {
        this.overlaySearchState.next(false);
      }
    });
  }

  getRouteDataDeep(route: ActivatedRouteSnapshot): any {
    const result = {};

    const queue = [route];

    while (queue.length > 0) {
      const current = queue.pop();

      Object.assign(result, current.data);
      for (const child of current.children) {
        queue.push(child);
      }
    }

    return result;
  }

  ngAfterViewInit(): void {
    const topNavigationElement: HTMLElement =
      document.createElement('top-navigation');
    topNavigationElement.setAttribute('is-mrt', 'true');
    topNavigationElement.setAttribute('is-mrt-search', 'true');
    topNavigationElement.setAttribute(
      'script-base-url',
      this.appNavService.getScriptBaseUrl()
    );
    this.topnav.nativeElement.appendChild(topNavigationElement);
    const topNavigationScript: HTMLScriptElement =
      document.createElement('script');
    topNavigationScript.setAttribute('src', this.appNavService.getScriptUrl());
    document.body.appendChild(topNavigationScript);

    const topNavigationStyles = document.createElement('link');
    topNavigationStyles.rel = 'stylesheet';
    topNavigationStyles.href = `${this.appNavService.getScriptBaseUrl()}styles.css`;
    document.head.appendChild(topNavigationStyles);

    this.unlistener = this.renderer.listen(
      topNavigationElement,
      'navigation',
      (evt: CustomEvent<NavigationEvent>) => {
        const eventBody = evt.detail;

        if (eventBody.eventType === 'route') {
          const urlParts = eventBody.route.split(/[?=]/);
          const queryParams = {};
          for (let i = 1; i < urlParts.length; i = i + 2) {
            queryParams[urlParts[i]] = urlParts[i + 1];
          }

          void this.router.navigate([urlParts[0]], {
            queryParams: queryParams,
            replaceUrl: true
          });
        } else if (eventBody.eventType === 'search-query') {
          const routeData = this.getRouteDataDeep(
            this.router.routerState.snapshot.root
          );

          if (routeData.disableSearchOverlay) {
            void this.router.navigate(['search', eventBody.searchQuery.type], {
              queryParams: {
                query: eventBody.searchQuery.query
              }
            });
          } else {
            this.overlaySearchState.next(true);
            this.searchEvents.next(eventBody.searchQuery);
          }
        } else if (eventBody.eventType === 'search-input-action') {
          const routeData = this.getRouteDataDeep(
            this.router.routerState.snapshot.root
          );

          if (
            eventBody.inputAction.searchBegan ||
            eventBody.inputAction.enterPressed
          ) {
            if (!routeData.disableSearchOverlay) {
              this.overlaySearchState.next(true);
            }
          }
        } else if (eventBody.eventType === 'open-global-settings') {
          this.modalService.open(NotificationSettingsComponent);
        }

        // if (urlParts[0].indexOf('search') < 0) {
        //   topNavigationElement.removeAttribute('is-mrt-search');
        // } else {
        //   topNavigationElement.setAttribute('is-mrt-search', 'true');
        // }
      }
    );

    this._eventBus.watchlistUpdate.subscribe(event => {
      console.debug('sending data to app-nav', event);
      topNavigationElement.dispatchEvent(
        new CustomEvent('pixEvent', {
          detail: {
            eventType: `${event.type as string}-watchlist`
          }
        })
      );
    });

    // this.appNavService.onSetSelected.subscribe({
    //   next: (navId) => {
    //     topNavigationElement.setAttribute('selected-nav', navId);
    //   }
    // });

    // this.appNavService.onSetIsMrtSearch.subscribe({
    //   next: (value) => {
    //     if (value) {
    //       topNavigationElement.setAttribute('is-mrt-search', value);
    //     } else {
    //       topNavigationElement.removeAttribute('is-mrt-search');
    //     }
    //   }
    // });
  }

  ngOnDestroy(): void {
    this.unlistener();
  }

  onCloseOverlay() {
    this.overlaySearchState.next(false);
  }
}
