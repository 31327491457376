import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseApiService } from './base-api-service';

export interface StructuredAppData {
  name: string;
  os: string;

  score: string | 'N/A' | undefined;
  appStoreRating: number | 'N/A' | undefined;

  // i don't know if we have this one
  appStoreRatingCount: number | 'N/A' | undefined;

  appStorePrice: number | 'N/A' | undefined;

  ageGroup: string | undefined;
  canonicalPath: string | undefined;

  image: string | undefined;

  developerName: string | undefined;
  developerUrl: string | undefined;

  available: boolean | undefined;
}

export interface StructuredDomainData {
  domain: string;
  score?: string;
  // todo
}

const appOSDescriptorMap = {
  android: 'ANDROID',
  ios: 'IOS',
  roku: 'ROKU',
  firetv: 'AMAZON FIRE TV',
  samsung: 'SAMSUNG',
  tvos: 'TVOS'
};

const osTitleMap = {
  android: {
    app: 'Android App',
    appStore: 'Google App Rating'
  },
  ios: {
    app: 'iOS App',
    appStore: 'Apple App Rating'
  },
  roku: {
    app: 'Roku App',
    appStore: 'Roku App Rating'
  },
  firetv: {
    app: 'Fire TV App',
    appStore: 'Amazon App Rating'
  },
  samsung: {
    app: 'Samsung App',
    appStore: 'Samsung App Rating'
  },
  tvos: {
    app: 'tvOS App',
    appStore: 'tvOS App Rating'
  },
  domain: {
    // todo
    // "app": "Website",
    // "appStore": ""
  }
};

interface OsDescription {
  os: string;
  appStore: string;
}

const osDescriptionMap: { [key: string]: OsDescription } = {
  android: {
    os: 'Android',
    appStore: 'Google Play Store'
  },
  ios: {
    os: 'iOS',
    appStore: 'Apple App Store'
  },
  roku: {
    os: 'Roku',
    appStore: 'Roku Channel Store'
  },
  firetv: {
    os: 'Fire TV',
    appStore: 'Amazon Fire TV App store'
  },
  samsung: {
    os: 'Samsung',
    appStore: 'Samsung Galaxy Store'
  },
  tvos: {
    os: 'tvOS',
    appStore: 'tvOS Store'
  },
  domain: {
    os: 'Website',
    appStore: ''
  }
};

@Injectable({
  providedIn: 'root'
})
export class PageMetaService {
  private structuredDataTag: HTMLScriptElement | undefined;
  private canonicalTag: HTMLLinkElement | undefined;

  public currentPageName$: Observable<string>;

  private pageTitle: BehaviorSubject<string> = new BehaviorSubject('');

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private meta: Meta,
    private title: Title,
    private env: BaseApiService
  ) {
    this.currentPageName$ = this.pageTitle.asObservable();
  }

  buildStructuredDomainData(data: StructuredDomainData) {
    return {
      '@context': 'https://schema.org'
      // todo
    };
  }

  buildStructuredAppData(data: StructuredAppData) {
    let ratingValue = '0';
    let ratingCount = '';
    const reviewCount = '1';

    let price = '';

    if (data.score != null && data.score !== 'N/A') {
      ratingCount = '1';
      ratingValue = `${data.score}%`;
    } else {
      if (data.appStoreRating != null && data.appStoreRating !== 'N/A') {
        if (data.appStoreRating <= 0) {
          ratingValue = '0';
        } else if (
          data.os === 'roku' ||
          data.os === 'firetv' ||
          data.os === 'samsung' ||
          data.os === 'tvos'
        ) {
          ratingValue = `${data.appStoreRating}%`;
        } else {
          ratingValue = data.appStoreRating.toString(); // permit decimals or no?
        }
      }

      if (
        data.appStoreRatingCount != null &&
        data.appStoreRatingCount !== 'N/A'
      ) {
        if (data.appStoreRatingCount <= 0) {
          ratingCount = '';
        } else {
          ratingCount = data.appStoreRatingCount.toString();
        }
      }
    }

    if (!data.appStorePrice) {
      price = '0';
    } else if (data.appStorePrice === 'N/A') {
      price = '0';
    } else {
      price = data.appStorePrice.toString();
    }

    let author = undefined;

    if (data.developerName) {
      let url = data.developerUrl;

      if (url && !url.match(/^https?:\/\//i)) {
        url = `https://${url}`;
      }

      author = {
        '@type': 'Person',
        name: data.developerName,
        url: url
      };
    }

    const osDescription = osDescriptionMap[data.os];

    let availability = undefined;

    if (data.available !== undefined) {
      availability = data.available
        ? 'https://schema.org/InStock'
        : 'https://schema.org/OutOfStock';
    }

    return {
      '@context': 'https://schema.org',
      '@type': 'SoftwareApplication',
      applicationCategory: 'MobileApplication', // extension: provide category based on IAB category
      name: data.name,
      operatingSystem: appOSDescriptorMap[data.os],
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: ratingValue,
        ratingCount: ratingCount,
        reviewCount: reviewCount,
        worstRating: '0',
        bestRating: '5'
      },
      offers: {
        '@type': 'Offer',
        price: price,
        priceCurrency: 'USD',
        availability: availability
        // "availability": "https://schema.org/InStock"
        // "availability": "https://schema.org/OutOfStock" or "https://schema.org/Discontinued"
      },

      // other possible attributes:
      contentRating: data.ageGroup,
      image: data.image,
      author: author,
      description: this.buildMetaDescription(data.name, osDescription),
      url: this.getCanonicalURL(data.canonicalPath)
    };
  }

  setStructuredData(data: object) {
    if (!this.structuredDataTag) {
      this.structuredDataTag = this.document.createElement('script');
      this.structuredDataTag.type = 'application/ld+json';
      this.document.head.appendChild(this.structuredDataTag);
    }

    this.structuredDataTag.innerText = JSON.stringify(data);
  }

  clearStructuredData() {
    if (this.structuredDataTag) {
      this.structuredDataTag.innerText = '{}';
    }
  }

  getCanonicalURL(path: string) {
    return `${this.env.getProtocol()}//${this.env.getPageHost()}${path}`;
  }

  /**
   * @example setCanonicalURL( "/insight/apps/<app>/GLOBAL/android/smartphone" );
   * @param {string} path The slash-prefixed path to the canonical URL, not including the protocal or hostname.
   */
  setCanonicalURL(path: string) {
    const url = this.getCanonicalURL(path);

    if (!this.canonicalTag) {
      this.canonicalTag = this.document.createElement('link');
      this.canonicalTag.setAttribute('rel', 'canonical');
      this.document.head.appendChild(this.canonicalTag);
    }

    this.canonicalTag.setAttribute('href', url);
  }

  clearCanonicalURL() {
    if (this.canonicalTag) {
      this.canonicalTag.removeAttribute('href');
    }
  }

  setInternalPageName(title: string) {
    this.pageTitle.next(title);
  }

  setTitle(appName: string, os: string) {
    const description = osTitleMap[os];

    if (!description) throw new Error('invalid os passed ' + os);

    this.setInternalPageName(`${appName} Insights`);

    if (os !== 'domain') {
      this.title.setTitle(
        `${appName} ${description.app} | ${description.appStore} | Pixalate`
      );
    } else {
      // todo: domain title format
      // this.title.setTitle( `${appName} ${description.app} | ${description.appStore} | Pixalate` );
    }
  }

  buildMetaDescription(appName: string, description: OsDescription) {
    return `Review the ratings of ${appName} featured in the ${description.appStore}. This ${description.os} app programmatic quality rankings and insights can be used in many advertising metrics, such as Viewability, IVT, Popularity, Ad Spend, Ad Density, and more.`;
  }

  setMetaDescription(appName: string, os: string) {
    const description = osDescriptionMap[os];

    if (!description) throw new Error('invalid os passed ' + os);

    if (os !== 'domain') {
      this.meta.updateTag({
        name: 'description',
        content: this.buildMetaDescription(appName, description)
      });
    } else {
      // todo: domain meta description
      // this.meta.updateTag({
      //   name: 'description',
      //   content: `Review the ratings of ${appName} featured in the ${description.appStore}. This ${description.os} app programmatic quality rankings and insights can be used in many advertising metrics, such as Viewability, IVT, Popularity, Ad Spend, Ad Density, and more.`
      // })
    }
  }

  clearMetaDescription() {
    this.meta.removeTag('description');
  }

  /**
   * Update the meta tag associated with crawlers.
   * This should be set to `false` on pages with limited data and `true` for pages with full data.
   * @param {boolean} allow Whether to allow robots on the page.
   */
  setAllowRobots(allow: boolean) {
    this.meta.updateTag({
      name: 'robots',
      content: allow ? 'all' : 'noindex'
    });
  }
}
