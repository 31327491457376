import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { User } from '../models/User';
import { AuthService, AuthState } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private userService: User, private auth: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return new Promise<boolean>(resolve => {
      const authState = this.auth.getAuthState();

      this.userService.establishSession();

      switch (authState) {
        case AuthState.Anonymous:
        case AuthState.Authenticated:
          resolve(true);
          break;
        case AuthState.ExpiredToken: {
          this.auth.logout();
          break;
        }
      }
    });
  }
}
