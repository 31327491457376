import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-kcs-app-title',
  templateUrl: './kcs-app-title.component.html',
  styleUrls: [
    './kcs-app-title.component.scss',
    '../../kcs-base/kcs-base-common.scss'
  ]
})
export class KcsAppTitleComponent implements OnInit {
  @Input()
  hit: any;

  @Input()
  indexName: string;

  @Input()
  isFreemium: boolean;

  @Input()
  viewType: string;

  @Output()
  filterClick: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  onFilterClick(appStore: string) {
    this.filterClick.emit(appStore);
  }
}
