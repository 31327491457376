import { Component, Inject, forwardRef, Optional, Input } from '@angular/core';
import {
  TypedBaseWidget,
  NgAisInstantSearch,
  NgAisIndex
} from 'angular-instantsearch';

import connectNumericMenu, {
  NumericMenuWidgetDescription,
  NumericMenuConnectorParams,
  NumericMenuConnectorParamsItem
} from 'instantsearch.js/es/connectors/numeric-menu/connectNumericMenu';

@Component({
  selector: 'app-numeric-menu-select',
  templateUrl: './numeric-menu-select.component.html',
  styleUrls: ['./numeric-menu-select.component.scss']
})
export class NumericMenuSelectComponent extends TypedBaseWidget<
  NumericMenuWidgetDescription,
  NumericMenuConnectorParams
> {
  @Input()
  attribute: string | undefined;

  @Input()
  items: NumericMenuConnectorParamsItem[];

  public state: NumericMenuWidgetDescription['renderState']; // Rendering options
  constructor(
    @Inject(forwardRef(() => NgAisIndex))
    @Optional()
    public parentIndex: NgAisIndex,
    @Inject(forwardRef(() => NgAisInstantSearch))
    public instantSearchInstance: NgAisInstantSearch
  ) {
    super('NumericMenu');
  }
  ngOnInit() {
    this.createWidget(connectNumericMenu, {
      // instance options
      attribute: this.attribute,
      items: this.items
    });
    super.ngOnInit();
  }
}
