<ng-container *ngIf="config && showingDelay$ | async">
  <ais-instantsearch [config]="config">
    <ais-configure [searchParameters]="{
      hitsPerPage: ( bp$ | async ) <= Breakpoint.sm ? 5 : 9,
      query: query,
      filters: formattedFilterQuery,
      page: page,
      attributesToSnippet: ['description:10'],
      snippetEllipsisText: '&hellip;',
      restrictSearchableAttributes: restrictSearchableAttributes
    }">
    </ais-configure>

    <header>
      <div class="header-body d-flex flex-column flex-lg-row justify-content-between">
        <div [ngClass]="{'d-none':!isOverlay}">
          <app-kcs-overlay-close-button (close)="closeButtonClick()"></app-kcs-overlay-close-button>
        </div>
        <div
          class="pill-buttons d-flex justify-content-start overflow-x-auto {{ isOverlay ? 'justify-content-lg-between' : 'justify-content-lg-center'}}">
          <app-kcs-pill-filters [filterKey]="filterKey" [selectedFiltersArray]="selectedFiltersArray"
            (filterClick)="onFilterClick($event)">
          </app-kcs-pill-filters>
        </div>
      </div>
    </header>

    <section class="body-content">
      <div class="filters-cont">
        <app-kcs-side-filters [primaryIndex]="indexName" [currentUiState]="instantSearchState | async"
          [filters]="keywordFilters" [isOverlay]="isOverlay" [showMobile]="showSideFiltersMobile"
          (navCloseClick)="onNavCloseClick()">
        </app-kcs-side-filters>
      </div>
      <div class="results-cont">
        <app-kcs-results key="title" [searchContext]="searchContext" [resultTemplate]="contentView"
          [restrictSearchableAttributes]="restrictSearchableAttributes" (mobileFilterMenuClick)="onNavOpenClick()">
        </app-kcs-results>
      </div>
    </section>
  </ais-instantsearch>
</ng-container>
<app-scroll-to-top [target]="scrollContainer?.nativeElement"></app-scroll-to-top>

<ng-template #contentView let-hits="hits" let-viewType="viewType" let-instantSearchInstance="instantSearchInstance">
  <div class="results-container" [ngClass]="[viewType]">
    <ng-container *ngFor="let hit of hits">
      <ng-container *ngIf="hit?.appStore === 'domain'">
        <app-kcs-domain-tile [hit]="hit" [indexName]="indexName" [isFreemium]="isFreemium" [viewType]="viewType">
        </app-kcs-domain-tile>
      </ng-container>
      <ng-container *ngIf="hit?.appStore !== 'domain'">
        <app-kcs-app-title [hit]="hit" [indexName]="indexName" [isFreemium]="isFreemium" [viewType]="viewType"
          (filterClick)="onFilterClick($event)"></app-kcs-app-title>
      </ng-container>
    </ng-container>
  </div>
</ng-template>