import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'screenshot'
})
export class PlatformAwareScalerPipe implements PipeTransform {
  transform(value: string, os: string, height: number) {
    switch (os) {
      case 'android':
        let suffixIndex = value.lastIndexOf('=');
        return `${value.substring(0, suffixIndex)}=h${height}`;
      case 'ios':
        let filter = value.lastIndexOf('/');
        let imgWithoutFilter = value.substring(0, filter);
        return `${imgWithoutFilter}/0x${height}h.png`;
      case 'roku':
        // getting 500 response codes in ff sometimes due to Accept header value of "image/avif,image/webp,*/*"
        // let width = Math.round( height * 1.7621145374 ); // aspect ratio for 800x454
        // return `https://cigars.roku.com/v1/contain/${width}x${height}/${encodeURIComponent( value )}`;
        return value;
      case 'firetv':
        let extIndex = value.lastIndexOf('.');
        let imgWithoutExt = value.substring(0, extIndex);
        let ext2 = value.substring(extIndex);
        return `${imgWithoutExt}._SY${height}_${ext2}`;
      case 'samsung':
        let firstIndex = value.indexOf(',');
        if (firstIndex == -1) {
          firstIndex = value.length;
        }
        let img = value.substring(0, firstIndex);
        return img;
      case 'tvos':
        // https://is1-ssl.mzstatic.com/image/thumb/PurpleSource116/v4/ab/b1/0e/abb10e58-7a4e-9909-c822-426e8237b7f8/b33f9ef7-7e70-42ed-907a-b2e3f3f50eef_Dashboard.png/720x405bb.png
        return value;
    }
  }
}
