<div class="footer-wrapper">
  <div class="feedback">
    <a class="btn btn-link" (click)="showFeedback()">
      <i class="fa fa-bullhorn"></i>&nbsp;Have Feedback?
    </a>
  </div>
  <div class="feedback-form" [hidden]="!showFeedbackForm">
    <div class="feedback-message-cont">
      <h5>Message:</h5>
      <textarea></textarea>
    </div>

    <div class="feedback-button-bar">
      <button class="btn btn-secondary" (click)="showFeedbackForm=false">Cancel</button>
      <button class="btn btn-primary" (click)="sendFeedback();">Send Feedback</button>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col text-center">
        <div class="d-flex flex-row flex-wrap justify-content-between">
          <div>
            <img class="mr-2" src="/assets/images/footer-logo.png" alt="Footer Logo">
            <small>Copyright © 2022 Pixalate</small>
          </div>
          <div>
            <small class="d-flex flex-wrap" style="line-height: 30px;">
              <a role="button" href="https://www.pixalate.com/terms" target="_blank">Terms of Use</a><span>&nbsp;|&nbsp;</span>
              <a role="button" href="https://www.pixalate.com/privacypolicy/" target="_blank">Privacy Policy</a><span>&nbsp;|&nbsp;</span>
              <a role="button" href="mailto:support@pixalate.com">info@pixalate.com</a><span>&nbsp;|&nbsp;</span>
              <a href="https://ratings.pixalate.com/glossary/apps/A/1.html">All Mobile Apps</a><span>&nbsp;|&nbsp;</span>
              <a href="https://ratings.pixalate.com/glossary/ctv/A/1.html">All CTV Apps</a>
            </small>
          </div>
        </div>
      </div>
      <!--<div class="col-lg-2" align="center">-->
      <!--<small>{{version}}</small>-->
      <!--</div>-->
    </div>
  </div>
</div>
