import { Observable, of, throwError } from 'rxjs';
import { delay, mergeMap, retryWhen } from 'rxjs/operators';

export function templateParseHandler(key, value) {
  if (key.endsWith('Template')) {
    return undefined;
  } else {
    return value;
  }
}

export function retryWithBackoff(
  baseDelay: number,
  maxRetries: number,
  backoffDelay: number = 1000
) {
  let retries = maxRetries;

  return (src: Observable<any>) => {
    return src.pipe(
      retryWhen(errs => {
        return errs.pipe(
          mergeMap(err => {
            if (retries-- > 0) {
              const totalDelay =
                baseDelay + (maxRetries - retries) * backoffDelay;
              return of(err).pipe(delay(totalDelay));
            } else {
              return throwError(
                'Failed to generate CSV in a reasonable amount of time. Giving up.'
              );
            }
          })
        );
      })
    );
  };
}
