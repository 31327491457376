import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

declare var jQuery: any;
export interface ParamMap {
    [key: string]: string;
}

@Component({
    selector: 'app-new-domain-details-legacy',
    templateUrl: './domain-details-legacy.component.html'
})
export class DomainDetailsLegacyComponent implements OnInit {

    constructor(private _route: ActivatedRoute,
        private _router: Router) {
    }

    ngOnInit(): void {

        let domain = this._route.snapshot.params['domain'];
        let region = this._route.snapshot.params['region'];
        let device = this._route.snapshot.params['device'];

        //https://ratings2.pixalate.com/domain-details/espn.com/US/desktop
        //https://ratings2.pixalate.com/insight/domains/espn.com/US/desktop

        if(domain && region && device) {
            this._router.navigate(['/insight/domains', domain,region,device], {replaceUrl: true});
        }
    }
}
