import {Injectable, Pipe, PipeTransform} from '@angular/core';
import {
  DEFAULT_PAGE_OPTION_LS_PREFIX,
  PAGE_OPTIONS,
  PreferenceOption
} from '../components/new-navigation/userprofile/preferences/preferences.config';

@Pipe({name: 'insightsrouterlink'})
@Injectable()
export class InsightsRouterLinkPipe implements PipeTransform {

  transform(tryTheseItem: any, insighttype: string, qparams: Array<string>, indexes: Array<number>): any {
    const domainParamMap = {};
    const prefKey = `insight-${insighttype}`;
    qparams.forEach((qparam) => {
      domainParamMap[qparam] = PAGE_OPTIONS
        .filter((pageOption) => pageOption.id === prefKey)[0].params
        .filter((param) => param.id === qparam)[0].selected.id;
    })

    const params = PAGE_OPTIONS.filter((pageOption) => pageOption.id === prefKey)[0].params;
    params.forEach((param) => {
      const pagePrefOption = localStorage.getItem(`${DEFAULT_PAGE_OPTION_LS_PREFIX}${prefKey}-${param.id}`);
      if (pagePrefOption) {
        const pagePrefOptionObj: PreferenceOption = JSON.parse(pagePrefOption);
        domainParamMap[param.id] = pagePrefOptionObj.id;
      }
    });

    qparams.forEach((qParam, index) => {
      tryTheseItem.routerLink[indexes[index]] = domainParamMap[qParam]
    });

    return tryTheseItem.routerLink;
  }

}
