<div class="main-center-content" [ngClass]="{ 'no-scroll-container': overlaySearchState | async }">
  <div class="top-navigation-container" #topnav></div>
  <div class="router-content">
    <router-outlet></router-outlet>
    <app-kcs-overlay *ngIf="overlaySearchState | async" (close)="onCloseOverlay()" [searchEvents]="searchEvents"
      [isOverlay]="true">
    </app-kcs-overlay>
  </div>
  <footer-c class="footer-cont"></footer-c>
</div>
<app-fna-popup></app-fna-popup>
