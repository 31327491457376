import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const appRoutes: Routes = [ ];

export const appRoutingProviders: any[] = [

];

export const routing: ModuleWithProviders<any> = RouterModule.forChild(appRoutes);
