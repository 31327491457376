<ng-container *ngIf="state?.items">
  <div class="d-flex" *ngIf="yesno && !yesnoMapping">
    <a class="yesno {{item.label}}" [ngClass]="{ active: item.isRefined }" *ngFor="let item of state?.items"
      (click)="state.refine(item.value)">
      {{item.label}}
    </a>
  </div>
  <div class="list-group" *ngIf="!yesno || yesnoMapping">
    <a class="list-group-item list-group-item-action" [ngClass]="{ active: item.isRefined }"
      *ngFor="let item of state?.items" (click)="state.refine(item.value)">
      {{item.label | titlecase}}
      <i class="active-icon fa fa-check" *ngIf="item.isRefined"></i>
    </a>
  </div>
</ng-container>
