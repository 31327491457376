import { Component } from '@angular/core';
import { KcsBaseComponent } from '../kcs-base/kcs-base.component';
import { CONTENT_FILTERS, SEARCH_CONTEXT } from '../ALGOLIA_CONFIG';

@Component({
  selector: 'app-content-keyword-contextual-search',
  templateUrl: './kcs-content-search.component.html',
  styleUrls: [
    '../kcs-base/kcs-base-common.scss',
    '../kcs-base/kcs-base.component.scss'
  ]
})
export class KcsContentSearchComponent extends KcsBaseComponent {
  keywordFilters = CONTENT_FILTERS;
  searchContext = SEARCH_CONTEXT.CONTENT;
  filterKey = 'appStore';
  filterUrlKey = undefined;
  config = this.makeConfig();

  isFreemium: boolean;

  ngOnInit() {
    super.ngOnInit();
    if (!this.showCoppaFilter()) {
      this.keywordFilters = CONTENT_FILTERS.filter(
        f => f.attribute != 'coppaTargetChildren'
      );
    }

    this.isFreemium = this.isFreemiumUser();
  }
}
