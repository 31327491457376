import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgAisModule } from 'angular-instantsearch';
import { PipesModule } from 'app/pipes/pipes.module';
import { KcsSideFiltersComponent } from './kcs-side-filters/kcs-side-filters.component';
import { KcsResultsComponent } from './kcs-results/kcs-results.component';
import { ClearRefinementsComponent } from './ais-widgets/clear-refinements/clear-refinements.component';
import { KeywordSearchBoxComponent } from './ais-widgets/keyword-search-box.component';
import { KeywordRefinementListComponent } from './ais-widgets/refinement-list/keyword-refinement-list.component';
import { NumericMenuSelectComponent } from './ais-widgets/numeric-menu-select/numeric-menu-select.component';
import { DateRangePickerComponent } from './ais-widgets/date-range-picker/date-range-picker.component';
import { KcsPillFiltersComponent } from './kcs-pill-filters/kcs-pill-filters.component';
import { SharedModule } from 'app/components/shared/shared.module';
import { KcsContentSearchComponent } from './kcs-content-search/kcs-content-search.component';
import { KcsPrivacyPolicySearchComponent } from './kcs-privacy-policy-search/kcs-privacy-policy-search.component';
import { StripNlnlPipe } from './strip-nlnl.pipe';
import { HitIconPipe } from './hit-icon.pipe';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from '../../../directives/directives.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AttributeToggleBtnDirective } from './ais-widgets/attribute-toggle-btn/attribute-toggle-btn.directive';
import { KcsMenuComponent } from './ais-widgets/kcs-menu/kcs-menu.component';
import { CurrentRefinementsComponent } from './ais-widgets/current-refinements/current-refinements.component';
import { KcsOverlayCloseButtonComponent } from './kcs-overlay-close-button/kcs-overlay-close-button.component';
import { KcsTitleSearchComponent } from './kcs-title-search/kcs-title-search.component';
import { KcsDomainTileComponent } from './ais-widgets/kcs-domain-tile/kcs-domain-tile.component';
import { KcsAppTitleComponent } from './ais-widgets/kcs-app-title/kcs-app-title.component';

@NgModule({
  declarations: [
    KcsOverlayCloseButtonComponent,
    KcsContentSearchComponent,
    KcsPrivacyPolicySearchComponent,
    KcsSideFiltersComponent,
    KcsResultsComponent,
    ClearRefinementsComponent,
    CurrentRefinementsComponent,
    KeywordSearchBoxComponent,
    KeywordRefinementListComponent,
    NumericMenuSelectComponent,
    DateRangePickerComponent,
    KcsPillFiltersComponent,
    AttributeToggleBtnDirective,
    HitIconPipe,
    StripNlnlPipe,
    KcsMenuComponent,
    KcsTitleSearchComponent,
    KcsDomainTileComponent,
    KcsAppTitleComponent
  ],
  imports: [
    CommonModule,
    PipesModule,
    SharedModule,
    RouterModule,
    DirectivesModule,
    FormsModule,
    ReactiveFormsModule,
    NgAisModule.forRoot()
  ],
  exports: [
    KcsContentSearchComponent,
    KcsPrivacyPolicySearchComponent,
    KcsTitleSearchComponent
  ]
})
export class KeywordContextualSearchModule {}
