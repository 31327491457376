/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Injectable } from '@angular/core';
import { User } from '../models/User';
import { ApigeeService } from './apigee.service';
import { EventBusService } from './EventBusService';
import { Watchlist } from './watchlist';
import { typeCollectionMap } from './type-collection-map';
import { Follow } from './follow';
import { AuthService, AuthState } from './auth.service';
import { NotificationService } from './notification.service';

const SYNC_TIMEOUT = 5000;

@Injectable()
export class WatchlistService {
  watchlist: Watchlist = new Watchlist(null);
  syncTimeout;
  syncing: boolean;
  _syncInvalid: boolean;
  syncPromise: Promise<void>;
  _endSync: Function;

  constructor(
    private _apigeeService: ApigeeService,
    private _notificationService: NotificationService,
    public _userService: User,
    public _authService: AuthService,
    private _eventBus: EventBusService
  ) {
    this._authService.onAuthStateChange.subscribe(evt =>
      this.onAuthStateChange(evt)
    );

    void this.syncWatchlist();
  }

  onAuthStateChange(evt: AuthState): void {
    void this.syncWatchlist();
  }

  startSync() {
    if (this._userService.getIdentity()) {
      const email = this._userService.getIdentity()['userEmailAddress'];
      if (email) {
        this.watchlist = new Watchlist(email);
      } else {
        this.watchlist = new Watchlist(null);
      }
    }

    this.syncing = true;
    this.syncPromise = new Promise<void>(rev => {
      this._endSync = rev;
    });
  }

  invalidateSync() {
    if (this.syncing) {
      this._syncInvalid = true;
    }
  }

  endSync() {
    this.syncing = false;
    this._syncInvalid = false;
    if (this._endSync) this._endSync();
    this._endSync = null;

    this._eventBus.notifyWatchlistUpdate({
      type: 'sync',
      watchlist: this.watchlist
    });
  }

  isFollowing(id: string, type: string) {
    return this.watchlist.isFollowing(id, type);
  }

  async addFollow(id: string, type: string, os: string) {
    try {
      this.watchlist.addFollow(id, type);
      this._eventBus.notifyWatchlistUpdate({
        type: 'add',
        follow: { id, type },
        watchlist: this.watchlist
      });

      if (this.syncing) {
        this.invalidateSync();
        await this.syncPromise;
      }

      await this._apigeeService.addToWatchlistById(
        id,
        this.watchlist.userId,
        typeCollectionMap[type]
      );
      await this._notificationService.adjustNotificationSetting(id, os, null);
      this.requestSync();
    } catch (error) {
      console.error('error: ', error);
      throw error;
    }
  }

  async removeFollow(
    id: string,
    type: string,
    os: string,
    notNotify?: boolean
  ) {
    this.watchlist.removeFollow(id, type);

    if (!notNotify)
      this._eventBus.notifyWatchlistUpdate({
        type: 'remove',
        follow: { id, type },
        watchlist: this.watchlist
      });

    if (this.syncing) await this.syncPromise;

    await this._apigeeService.unfollowById(
      id,
      this.watchlist.userId,
      typeCollectionMap[type]
    );
    await this._notificationService.deleteNotificationSetting(id, os);
    this.requestSync(notNotify);
  }

  requestSync(notNotify?: boolean) {
    if (this.syncTimeout != null) {
      clearTimeout(this.syncTimeout);
    }

    this.syncTimeout = setTimeout(() => {
      void this.syncWatchlist(notNotify);
    }, SYNC_TIMEOUT);
  }

  async syncWatchlist(notNotify?: boolean) {
    if (this.syncing) return;

    try {
      this.startSync();

      if (this.watchlist && !this.watchlist.userId) {
        this.watchlist.userId =
          this._userService.getIdentity()['userEmailAddress'];
      }

      if (this.watchlist && this.watchlist.userId) {
        const data = await this._apigeeService.getFollowedByUser(
          this.watchlist.userId,
          null,
          false,
          true
        );
        const invalid = this._syncInvalid;
        if (!invalid) {
          const follows = <Array<Follow>>data.docs;
          this.watchlist.follows = follows;
        }
        this.endSync();
      } else {
        this.endSync();
      }
    } catch (err) {
      console.error(err);
      this.endSync();
    }
  }
}
