import { Component, EventEmitter, Output } from '@angular/core';
import { PageMetaService } from 'app/services/page-meta.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-kcs-overlay-close-button',
  templateUrl: './kcs-overlay-close-button.component.html',
  styleUrls: ['./kcs-overlay-close-button.component.scss']
})
export class KcsOverlayCloseButtonComponent {
  @Output() close: EventEmitter<void> = new EventEmitter();

  pageName$: Observable<string>;

  constructor(private pageMeta: PageMetaService) {
    this.pageName$ = pageMeta.currentPageName$;
  }

  closeButtonClick() {
    this.close.emit();
  }
}
