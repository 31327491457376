import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export interface RefinementDetail {
  attribute: string;
  value: string;
}

@Injectable({
  providedIn: 'root'
})
export class RefinementListService implements OnDestroy {
  private subject = new Subject<RefinementDetail>();

  public refine$: Observable<RefinementDetail> = this.subject.asObservable();

  refine(detail: RefinementDetail) {
    this.subject.next(detail);
  }

  ngOnDestroy(): void {
    this.subject.complete();
  }
}
