import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PipesModule } from 'app/pipes/pipes.module';
import { SharedModule } from 'app/components/shared/shared.module';
import { KcsOverlayComponent } from './kcs-overlay.component';
import { KeywordContextualSearchModule } from '../keyword-contextual-search.module';

@NgModule({
  declarations: [KcsOverlayComponent],
  imports: [
    CommonModule,
    PipesModule,
    SharedModule,
    KeywordContextualSearchModule
  ],
  exports: [KcsOverlayComponent]
})
export class KcsOverlayModule {}
