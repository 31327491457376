import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanLoad, Route, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { PageMetaService } from '../services/page-meta.service';

/**
 * Middleware to clear page metadata when navigating to a new page.
 */
@Injectable({
  providedIn: 'root'
})
export class MetaGuard implements CanLoad {
  constructor ( private meta: PageMetaService ) {}

  canLoad(): boolean {
    this.meta.clearCanonicalURL();
    this.meta.clearStructuredData();
    this.meta.clearMetaDescription();
    this.meta.setAllowRobots( true );

    return true;
  }
}
