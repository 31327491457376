/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';

declare const jQuery: any;

@Directive({ selector: '[enTooltip], [data-toggle="tooltip"]' })
export class TooltipDirective implements OnInit, OnDestroy {
  private el: HTMLElement;

  @Input('template') template;

  constructor(el: ElementRef) {
    this.el = el.nativeElement;
  }

  ngOnInit(): void {
    if (this.template && this.template.length > 0) {
      jQuery(this.el).tooltip({
        html: true,
        container: 'body',
        animation: false,
        template: this.template
      });
    }
  }

  ngOnDestroy() {
    jQuery(this.el).hide();
    jQuery(this.el).tooltip('hide');
  }

  @HostListener('mouseenter') onMouseEnter() {
    jQuery(this.el).css('cursor', 'pointer');
    this.showTooltip();
  }

  @HostListener('mouseleave') onMouseLeave() {
    jQuery(this.el).tooltip('hide');
  }

  @HostListener('click') onClick() {
    this.showTooltip();
  }

  showTooltip() {
    const elt = jQuery(this.el);

    let position = 'top';
    if (elt.offset().left < 100) {
      if (elt.width() > jQuery(window).width() - 100) {
        position = 'top';
      } else {
        position = 'right';
      }
    } else if (elt.offset().left > jQuery(window).width() - 100) {
      position = 'left';
    } else if (elt.offset().top < 100) {
      position = 'bottom';
    }

    const tip = elt.data('bs.tooltip');
    if (tip) {
      tip.config.placement = position;

      position = elt.data('tooltip-placement');
      if (position) {
        tip.config.placement = position;
      }
    }

    jQuery(this.el).tooltip('show');
  }
}
