import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnInit
} from '@angular/core';

declare var jQuery: any;

@Directive({ selector: '[enPopover], [data-toggle="popover"]' })
export class PopoverDirective implements OnInit {
  @Input()
  showTitle: boolean = false; // default showTitle is false

  @Input()
  autoHide: boolean = true; // default autoHide is true

  @Input()
  autoHideTime: number = 1000; // default 1 sec to autoHide

  @Input()
  tooltipHideTime: number = 500; // default 0.5 seco to auto hide tooltip

  @Input()
  insertClass: string = ''; // class to insert on popover

  private el: HTMLElement;

  constructor(el: ElementRef) {
    this.el = el.nativeElement;
  }

  ngOnInit(): void {
    let template = `<div class="popover ${this.insertClass}" role="tooltip">
                        <div class="arrow"></div>
                        ${
                          this.showTitle
                            ? '<h3 class="popover-header"></h3>'
                            : ''
                        }
                        <div class="popover-body"></div>
                    </div>`;

    jQuery(this.el).popover({
      template: template
    });

    let instance = this;
    if (this.autoHide) {
      jQuery(this.el).on('shown.bs.popover', function () {
        let _this = this;
        setTimeout(() => {
          jQuery(_this).popover('hide');
        }, instance.autoHideTime);
      });
    }

    jQuery(this.el).on('show.bs.popover', function () {
      let _this = this;
      if (jQuery(_this)[0].hasAttribute('entooltip')) {
        setTimeout(() => {
          // if it's also a tooltip we should hide it right away
          jQuery(_this).tooltip('hide');
        }, instance.tooltipHideTime);
      }
    });
  }
}
