<ng-container *ngIf="state?.items">
  <ng-container *ngFor="let item of state.items">
    <ng-container [ngSwitch]="item.type">
      <ng-container *ngSwitchCase="FILTERTYPE.DateRange">
        <div class="pill-filter d-inline-block">
          <span>{{ item.customLabel }}</span>
          <i class="fa fa-times remove" aria-hidden="true" (click)="customRefine(item.refinements)"></i>
        </div>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <ng-container *ngFor="let refinement of item.refinements">
          <div class="pill-filter d-inline-block">
            <span>{{ refinement.customLabel || item.customLabel }}</span>
            <i class="fa fa-times remove" aria-hidden="true" (click)="state.refine(refinement)"></i>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>

  <div class="d-inline-block">
    <app-clear-refinements></app-clear-refinements>
  </div>

</ng-container>
