/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  NOTIFICATION_CHANNELS,
  NOTIFICATION_FREQUENCY,
  NOTIFICATION_OPTIONS
} from 'app/constants/notification-settings';
import { getDataItemId, getDataItemOs } from 'app/functions/data-item-helpers';
import { NotificationService } from 'app/services/notification.service';

@Component({
  selector: 'app-notification-settings',
  templateUrl: './notification-settings.component.html',
  styleUrls: ['./notification-settings.component.scss']
})
export class NotificationSettingsComponent implements OnInit {
  @Input() data;

  settings;
  isLoading = false;
  toggleAllValue = false;

  notificationOptions = NOTIFICATION_OPTIONS;
  channelOptions = NOTIFICATION_CHANNELS;
  frequencyOptions = NOTIFICATION_FREQUENCY;

  constructor(
    public modal: NgbActiveModal,
    private _notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.settings = this._notificationService.getGlobalSettings();
    this.updateToggleAllStatus();

    if (this.data) {
      this.isLoading = true;
      void this._notificationService
        .getNotificationSetting(
          getDataItemId(this.data),
          getDataItemOs(this.data)
        )
        .then(settings => {
          this.settings = settings;
          this.isLoading = false;

          this.updateToggleAllStatus();
        });
    }
  }

  toggleAll(value): void {
    for (const key in this.settings.metrics) {
      this.settings.metrics[key] = value;
    }
    this.updateToggleAllStatus();
  }

  toggle(key, value): void {
    this.settings.metrics[key] = value;
    this.updateToggleAllStatus();
  }

  closeModal(): void {
    this.modal.dismiss();
  }

  submit() {
    if (!this.settings) return;

    if (this.data) {
      void this._notificationService.adjustNotificationSetting(
        getDataItemId(this.data),
        getDataItemOs(this.data),
        this.settings
      );
      // TODO: update list
      // if (data.status == 'ok' && data.adjusted > 0) {
      //   this.notificationSettings = this.notificationSettings.concat({
      //     id: dataItemId
      //   });
      // }
    } else {
      this._notificationService.updateGlobalSettings(this.settings);
    }

    this.modal.dismiss();
  }

  private updateToggleAllStatus() {
    this.toggleAllValue = !Object.values(this.settings.metrics).some(
      value => value === false
    );
  }
}
