import {Follow} from './follow';

export class Watchlist {
  userId: string;
  follows: Array<Follow>;

  constructor ( userId: string ) {
    this.userId = userId;
    this.follows = new Array<Follow>();
  }

  removeFollow ( id: string, type: string ): Follow {
    const index = this.follows.findIndex( ( follow ) => {
      return follow && follow.followId === id &&
        follow.followType === type;
    });

    if ( index > -1 ) {
      const follow = this.follows[ index ];
      this.follows.splice( index, 1 );
      return follow;
    } else {
      return null;
    }
  }

  getFollowListByType(type: string): Array<Follow> {
    return this.follows.filter( ( follow ) => {
      return follow && follow.followType === type;
    });
  }

  addFollow ( id: string, type: string ): Follow {
    const follow = new Follow( this.userId, id, type );
    this.follows.push( follow );

    return follow;
  }

  findFollow ( id: string, type: string ): Follow {
    return this.follows.find( ( follow ) => {
      return follow && follow.followId === id &&
        follow.followType === type;
    });
  }

  isFollowing ( id: string, type: string ): boolean {
    return this.findFollow( id, type ) != null;
  }
}
