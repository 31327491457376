import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output
} from '@angular/core';
import { RefinementListService } from '../refinement-list/refinement-list.service';

@Directive({
  selector: 'button[mrt-attribute-btn]'
})
export class AttributeToggleBtnDirective {
  @Input() attribute!: string;
  @Input() value: string;
  @Input() indexName: string;

  @Output() filterChange: EventEmitter<string> = new EventEmitter<string>();

  constructor(private refinementList: RefinementListService) {}

  @HostListener('click', ['$event'])
  onClick(evt: MouseEvent) {
    console.log('handling click');
    evt.preventDefault();
    evt.stopPropagation();

    this.refinementList.refine({
      attribute: this.attribute,
      value: this.value
    });

    // if (this.attribute.startsWith('filter:')) {
    //   this.filterChange.emit(this.value);
    // } else {
    //   this.instantSearchInstance.instantSearchInstance.setUiState(state => {
    //     const index = state[this.indexName];

    //     if (!index.refinementList) {
    //       index.refinementList = {};
    //     }

    //     let matchingRefinement = index.refinementList[this.attribute];

    //     const valString = this.value.toString();

    //     if (!matchingRefinement) {
    //       matchingRefinement = [valString];
    //     } else {
    //       const valIndex = matchingRefinement.indexOf(valString);
    //       if (valIndex === -1) {
    //         matchingRefinement.push(valString);
    //       } else {
    //         matchingRefinement.splice(valIndex, 1);
    //       }
    //     }
    //     index.refinementList[this.attribute] = matchingRefinement;

    //     return state;
    //   });
    // }
    // this.state.refine(this.value);
  }
}
