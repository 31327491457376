/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

/**
 * Get ID from the dataItem object
 *
 * @param dataItem
 * @returns
 */
export function getDataItemId(dataItem): string {
  return dataItem.domain || dataItem.appId;
}

/**
 * Get OS from the dataItem object
 *
 * @param dataItem
 * @returns
 */
export function getDataItemOs(dataItem): string {
  let os: string = dataItem.appStore || dataItem.device;
  if (dataItem.domain) {
    os = 'domain';
  }
  return os;
}
