import { catchError } from 'rxjs/operators';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { AuthService } from 'app/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.authService.getToken();
    if (token && token !== 'null') {
      request = request.clone({
        headers: request.headers.set('Authorization', 'Bearer ' + token)
      });
    }

    return next.handle(request).pipe(
      catchError(err => {
        if (err instanceof HttpErrorResponse) {
          // TODO: Add more error status

          if (err.status === 400 || err.status === 401) {
            // Handle unauthorized error
            console.warn('Unauthorized');

            this.authService.logout();

            // TODO: refresh the token first and only then if that fails go to the login page
          } else if (err.status === 500) {
            // Handler internal server error
            console.error('Server is not responding.');
          }
        }

        return throwError(err);
      })
    );
  }
}
