<aside>
  <ng-container *ngIf='mobile$ | async'>
    <button class='btn btn-link side-nav-mobile-close' (click)='navCloseClick.emit( $event )'>
      <i class='fa fa-times'></i>
    </button>
  </ng-container>
  <div class='side-nav-scroll-container'>
    <div class="d-flex justify-content-between">
      <h6>Filter</h6>
    </div>
    <div class="d-flex d-md-none flex-column align-items-center mb-4">
      <app-current-refinements></app-current-refinements>
    </div>
    <hr>
    <ng-container *ngFor="let filter of filters">
      <h6>{{ filter.name }}</h6>
      <ng-container [ngSwitch]="filter.type">

        <ng-container *ngSwitchCase="FILTER_TYPES.RefinementList">
          <app-kcs-refinement-list [attribute]="filter.attribute" [yesno]="filter.yesno"
            [yesnoMapping]="filter.yesnoMapping" [searchable]="filter.searchable"></app-kcs-refinement-list>
        </ng-container>

        <ng-container *ngSwitchCase="FILTER_TYPES.Menu">
          <app-kcs-menu [attribute]="filter.attribute" [yesno]="filter.yesno" [yesnoMapping]="filter.yesnoMapping">
          </app-kcs-menu>
        </ng-container>

        <ng-container *ngSwitchCase="FILTER_TYPES.NumericMenuSelect">
          <app-numeric-menu-select [attribute]="filter.attribute" [items]="filter.items"></app-numeric-menu-select>
        </ng-container>

        <ng-container *ngSwitchCase="FILTER_TYPES.DateRange">
          <app-date-range-picker [attribute]="filter.attribute"></app-date-range-picker>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <ng-container *ngFor="let subFilter of filter.subCategories">
            <label class="d-block menu" data-toggle="collapse" [attr.data-target]="'#' + subFilter.key"
              aria-expanded="false" [attr.aria-controls]="subFilter.key"
              (click)="subFilter.showSubCat = !subFilter.showSubCat">
              {{ subFilter.name }}
              <i class="dropdown-icon fa" [ngClass]="[ hasSelectedFilters( subFilter.attribute ) ? 'highlighted': '', subFilter.showSubCat ? 'fa-minus' : 'fa-plus']"></i>
            </label>
            <div class="collapse" [attr.id]="subFilter.key">
              <app-kcs-refinement-list [attribute]="subFilter.attribute" [searchable]="subFilter.searchable">
              </app-kcs-refinement-list>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</aside>
