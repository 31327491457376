import { Pipe, PipeTransform } from '@angular/core';

import numeral from 'numeral';

/**
 * Rounding off with significant digits
 */
@Pipe({ name: 'roundsigrange' })
export class RoundSigRangePipe implements PipeTransform {
  transform(value: string, format?: string): any {
    const range = value.split('-');
    const from = Number.parseInt(range[0]);
    const to = Number.parseInt(range[1]);
    const fromNumeral = numeral(from).format('0a').toUpperCase();
    const toNumeral = numeral(to).format('0a').toUpperCase();
    return `${fromNumeral} - ${toNumeral}`;
  }
}
