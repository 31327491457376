<ng-container *ngIf="config">
  <ais-instantsearch [config]="config">
    <ais-configure [searchParameters]="{
      hitsPerPage: ( bp$ | async ) <= Breakpoint.sm ? 5 : 9,
      query: query,
      filters: formattedFilterQuery,
      optionalWords: optionalWords,
      page: page,
      attributesToSnippet: ['pp_corpus:10'],
      snippetEllipsisText: '&hellip;'
    }">
    </ais-configure>

    <header>
      <div class="header-body d-flex flex-column flex-lg-row justify-content-between">
        <div [ngClass]="{'invisible':!isOverlay}">
          <app-kcs-overlay-close-button (close)="closeButtonClick()"></app-kcs-overlay-close-button>
        </div>
        <div class="pill-buttons d-flex justify-content-start overflow-x-auto">
          <app-kcs-pill-filters [filterKey]="filterKey" [selectedFiltersArray]="selectedFiltersArray"
            (filterClick)="onFilterClick($event)">
          </app-kcs-pill-filters>
        </div>
      </div>
    </header>

    <section class="body-content">
      <div class="filters-cont">
        <app-kcs-side-filters [filters]="keywordFilters" [isOverlay]="isOverlay" [showMobile]="showSideFiltersMobile"
          (navCloseClick)="onNavCloseClick()">
        </app-kcs-side-filters>
      </div>
      <div class="results-cont">
        <app-kcs-results [searchContext]="searchContext" [resultTemplate]="privacyView"
          (mobileFilterMenuClick)="onNavOpenClick()">
        </app-kcs-results>
      </div>
    </section>
  </ais-instantsearch>
</ng-container>
<app-scroll-to-top [target]="scrollContainer?.nativeElement"></app-scroll-to-top>

<ng-template #privacyView let-hits="hits" let-viewType="viewType" let-instantSearchInstance="instantSearchInstance">
  <div class="results-container" [ngClass]="[viewType]">
    <ng-container *ngFor="let hit of hits">
      <div class="result">
        <a [routerLink]="hit.routerLink">
          <img class="result-icon" [src]="hit.appIcon | screenshot:hit.appStore:72" />
        </a>
        <ul class="result-meta result-meta-header">
          <li class="result-meta-item">
            <button class="btn btn-link result-meta-link result-meta-item-block" mrt-attribute-btn
              [indexName]="indexName" attribute="filter:appStore" [instantSearchInstance]="instantSearchInstance"
              [value]="hit.appStore" (click)="onFilterClick(hit.appStore)">
              <ng-container [ngSwitch]="hit.appStore">
                <ng-container *ngSwitchCase="'roku'">
                  <img width="16" height="16" class="mr-1" src="/assets/images/roku.png" alt="Roku Icon">
                  <span>Roku</span>
                </ng-container>
                <ng-container *ngSwitchCase="'firetv'">
                  <img width="16" height="16" class="mr-1" src="/assets/images/firetv.png" alt="Fire TV Icon">
                  <span>Fire TV</span>
                </ng-container>
                <ng-container *ngSwitchCase="'samsung'">
                  <img width="16" height="16" class="mr-1" src="/assets/images/samsung.png" alt="Samsung Icon">
                  <span>Samsung</span>
                </ng-container>
                <ng-container *ngSwitchCase="'tvos'">
                  <img width="16" height="16" class="mr-1" src="/assets/images/tvos.png" alt="tvOS Icon">
                  <span>tvOS</span>
                </ng-container>
                <ng-container *ngSwitchCase="'android'">
                  <img width="16" height="16" class="mr-1" src="/assets/images/play-store.png" alt="Google Play Icon">
                  <span>Google Play</span>
                </ng-container>
                <ng-container *ngSwitchCase="'ios'">
                  <img width="16" height="16" class="mr-1" src="/assets/images/app-store.png" alt="App Store Icon">
                  <span>App Store</span>
                </ng-container>
              </ng-container>
            </button>
          </li>
        </ul>
        <div class="result-body">
          <h3 class="result-title">
            <a [routerLink]="hit.routerLink">
              <ais-highlight attribute="title" [hit]="hit"></ais-highlight>
            </a>
          </h3>
          <ul class="result-meta">
            <li class="result-meta-item justify-content-between">
              <div class="result-meta-item-block">
                Developer: <span>{{ hit.publisherName }}</span>
              </div>
              <div class="result-meta-item-block" *ngIf="hit.countryCode && hit.countryCode != 'GLOBAL'">
                <img class="country-flag" width="24"
                  src="//cdn.pixalate.com/icons/countries/flat/48/{{ hit.countryCode }}.png" alt="Country Logo">
              </div>
            </li>
          </ul>
          <ul class="result-meta result-meta-body">
            <li class="result-meta-item">
              {{ hit.bundleId || hit.appId || hit.trackId }}
            </li>
          </ul>
          <p>
            <a [attr.href]="hit.pp_privacyPolicyUrl" target="_blank">Open Policy</a>
          </p>
          <!-- <pre>
              {{ JSON.stringify( hit )}}
            </pre> -->
          <p class="result-description">
            <ng-container *ngIf="hit._highlightResult['pp_corpus']?.matchLevel != 'none'">
              <div [innerHTML]="hit._snippetResult?.pp_corpus?.value"></div>
            </ng-container>
          </p>
          <p class="result-matched" *ngIf="hit.matched">
            {{ hit.matched }} keyword{{ hit.matched === 1 ? '' : 's' }} matched
          </p>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>