<div class="modal-header">
  <h6 class="modal-title text-uppercase" [attr.id]="settings + '-label'">Edit {{ !this.data ? 'Global' : '' }}
    Notification Settings
  </h6>
  <button type="button" class="close" (click)="closeModal()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div [hidden]="!isLoading" class="loading-icon my-5 mx-auto" data-loader="circle-side"></div>
  <div class="container" *ngIf="!isLoading">
    <div class="d-flex flex-column">
      <div class="d-flex justify-content-end align-items-center pb-1">
        <span>Toggle All</span>
        <app-slider-toggle [value]="toggleAllValue" (update)="toggleAll($event)"></app-slider-toggle>
      </div>

      <ng-container *ngFor="let group of notificationOptions">
        <span class="sub-title fw-bold pb-1">{{ group.header }}</span>
        <ng-container *ngFor="let option of group.options; let last = last">
          <div class="d-flex justify-content-between pb-1" [ngClass]="{'pb-3 border-bottom': last}">
            <span>{{ option.label }}</span>
            <app-slider-toggle [value]="settings.metrics[option.key]"
              (update)="toggle(option.key, $event)"></app-slider-toggle>
          </div>
        </ng-container>
      </ng-container>
    </div>

    <div class="d-flex flex-column pt-3">
      <span class="mb-1">Where do you want to receive the notifications?</span>
      <p class="form-check" *ngFor="let channel of channelOptions">
        <label class="form-check-label">
          <input type="checkbox" class="form-check-input mr-2" [checked]="settings.channels[channel.key]"
            (change)="settings.channels[channel.key] = $event.target.checked" />
          {{ channel.label }}
        </label>
      </p>
    </div>

    <div class="d-flex flex-column pt-3">
      <div class="form-group mt-1">
        <label>How often do you want to receive the notifications?</label>
        <div class="form-check" *ngFor="let frequency of frequencyOptions">
          <label class="form-check-label">
            <input type="radio" class="form-check-input" name="optionsRadios" id="{{ 'option' + frequency.label }}"
              [value]="frequency.value" [checked]="settings.frequency == frequency.value"
              (click)="settings.frequency = frequency.value">
            {{ frequency.label }}
          </label>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <div class="d-flex justify-content-between">
    <button class="btn btn-link text-decoration-none px-4 py-2" (click)="closeModal()">Close</button>
    <button class="btn btn-primary px-4 py-2" type="button" [disabled]="isLoading" (click)="submit()">Save</button>
  </div>
</div>
