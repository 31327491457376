import {Injectable} from '@angular/core';
import {ChangeLogDoc} from 'app/models/ChangeLogDoc';
import {ProcessedChangeLogData} from 'app/models/ProccessedChangeLogData';
import {ChangeLogMonth} from 'app/models/ChangeLogMonth';
import {MONTH_ARRAY} from 'app/constants/MONTH_ARRAY';
import {DAY_ARRAY} from 'app/constants/DAY_ARRAY';
import {ChangeLogWeek} from 'app/models/ChangeLogWeek';

@Injectable()
export class AppChangelogService {

  processChangeLogData(changeLogData: Array<ChangeLogDoc>): Promise<ProcessedChangeLogData> {
    return new Promise((resolve) => {
      const changeLogKeys: Array<string> = [];
      const changeLogMonths: Array<ChangeLogMonth> = [];
      changeLogData.forEach((item) => {
        // console.log('item: ', item);
        const year = item.day.substring(0, 4);
        const month = item.day.substring(4, 6);
        const dayNum = item.day.substring(6, 8);

        let foundChangeLogMonth = changeLogMonths.find((i) => i.month === month);
        if (!foundChangeLogMonth) {
          foundChangeLogMonth = {
            month: month,
            label: MONTH_ARRAY[Number.parseInt(month) - 1],
            weeks: []
          };
          changeLogMonths.push(foundChangeLogMonth);
        }
        const d = new Date(`${year}-${month}-${dayNum}`);
        const itemDay = DAY_ARRAY[d.getDay()];
        const week: ChangeLogWeek = {
          day: itemDay,
          num: dayNum,
          doc: item
        };

        foundChangeLogMonth.weeks.push(week);
      });
      resolve({changeLogKeys: changeLogKeys, changeLogMonths: changeLogMonths});
    });
  }
}
