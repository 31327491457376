/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api-service';
import { User } from '../models/User';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';

declare let jQuery;

@Injectable()
export class CtvService extends BaseApiService {
  appDetailsCacheData: any = {};
  availableCountriesCache: any = {};
  availableMonthsCache: any = {};
  facetsDataCache: any = {};
  discoveryDataCache: any = {};
  appIconCoordinates: any;
  region: string;
  country: string;
  device: string;
  month: string;
  includeSpoofing: boolean;
  isFollower: boolean;
  reportDataCache: any = {};

  constructor(
    private http: HttpClient,
    private userService: User,
    private auth: AuthService
  ) {
    super();
  }

  private useAnonApi() {
    return this.userService.isFreemium() || !this.userService.ctvEnabled();
  }

  setFollowing(isFollower: boolean) {
    this.isFollower = isFollower;
  }

  storeAppIconCoordinates(coordinates: any) {
    this.appIconCoordinates = coordinates;
  }

  getCtvApps(start: number, limit: number, searchString: string) {
    searchString = encodeURIComponent(searchString);
    let url =
      `${this.getRatingsBaseUrl('2018')}/getCtvMetadata?` +
      `start=${start}&limit=${limit}&type=ctvAppInfo&title=${searchString}&cache=true`;
    if (this.useAnonApi()) {
      url = `${this.getApigeeBaseUrl(
        'v1'
      )}/ratings/search/ctv?title=${searchString}`;
    }
    return this.http.get(url);
  }
  getRokuDevelopers(start: number, limit: number, searchString: string) {
    return new Promise(resolve => {
      if (searchString && searchString.length > 0) {
        searchString = encodeURIComponent(searchString);
        const url =
          `${this.getRatingsBaseUrl('2018')}/getCtvMetadata?` +
          `start=${start}&limit=${limit}&type=ctvDeveloper&title=${searchString}&` +
          `cache=true`;
        this.http.get(url).subscribe(res => {
          if (res && res['docs'] && res['docs'].length > 0) {
            const developers = res['docs'];
            const retdevelopers = developers.map(developer => {
              return {
                isPublisher: true,
                publisherName: developer.developer,
                appsCount: 1,
                os: developer.os,
                device: developer.device
              };
            });
            resolve(retdevelopers);
          } else {
            resolve([]);
          }
        });
      }
    });
  }
  getRokuKeywords(start: number, limit: number, searchString: string) {
    return new Promise(resolve => {
      if (searchString && searchString.length > 0) {
        searchString = encodeURIComponent(searchString);
        const url =
          `${this.getRatingsBaseUrl('2018')}/getCtvMetadata?` +
          `start=${start}&limit=${limit}&type=ctvKeyword&title=${searchString}&` +
          `cache=true`;
        this.http.get(url).subscribe(res => {
          if (res && res['docs'] && res['docs'].length > 0) {
            const keywords = res['docs'];
            const retkeywords = keywords.map(keyword => {
              return {
                isKeyword: true,
                publisherName: keyword.keyword,
                appsCount: 1,
                os: keyword.os,
                device: keyword.device
              };
            });
            resolve(retkeywords);
          } else {
            resolve([]);
          }
        });
      } else {
        resolve([]);
      }
    });
  }
  setRegion(region: string) {
    this.region = region;
  }
  setCountry(country: string) {
    this.country = country;
  }
  setDevice(device: string) {
    this.device = device;
  }
  setMonth(month: string) {
    this.month = month;
  }
  setIncludeSpoofing(includeSpoofing: boolean) {
    this.includeSpoofing = includeSpoofing;
  }
  getAllData(
    appid: string,
    regionParam?: string,
    deviceParam?: string,
    countryParam?: string,
    monthParam?: string,
    includeSpoofingParam?: boolean
  ) {
    const device = deviceParam ? deviceParam : this.device;
    const region = regionParam ? regionParam : this.region;
    const country = countryParam ? countryParam : this.country;
    const month = monthParam ? monthParam : this.month;
    const isFreemium = this.useAnonApi();
    const isFollower = this.isFollower;
    const includeSpoofing =
      includeSpoofingParam != undefined
        ? includeSpoofingParam
        : this.includeSpoofing;
    return new Promise(resolve => {
      let urlString = '';
      if (isFreemium) {
        const queryParams = {
          region: region,
          os: device,
          month: month,
          country: country,
          isFreemium: isFreemium,
          isFollower: isFollower,
          includeSpoofing: includeSpoofing
        };
        const params = jQuery.param(queryParams);

        urlString = `https://${this.getApigeeHost()}/api/v1/insights/ctv/${appid}?${params}`;
      } else {
        let query = `WHERE appId = '${appid}' AND device = '${device}' AND region = '${region}'`;
        if (month && month.length > 0) {
          query += ` AND month = '${month}'`;
        }
        if (country && country.length > 0) {
          query += ` AND country = '${country}'`;
        }

        urlString =
          `${this.getReportBaseUrl(
            '2018'
          )}/getWidget?q=${query}&reportId=ctvMrt&` +
          `widgetId=ctvAppSummary&includeSpoofing=${includeSpoofing}`;
      }

      const cacheKey = `${this.auth.getAuthState()}:${urlString}`;
      const cacheData = this.appDetailsCacheData[cacheKey];

      if (cacheData) {
        resolve(JSON.parse(JSON.stringify(cacheData)));
      } else {
        this.http.get(urlString).subscribe(res => {
          this.storeCache(res, this.appDetailsCacheData, cacheKey);
          resolve(res);
        });
      }
    });
  }
  getAppScopeData(
    appid: string,
    regionParam?: string,
    deviceParam?: string,
    countryParam?: string,
    monthParam?: string,
    includeSpoofingParam?: boolean
  ) {
    const device = deviceParam ? deviceParam : this.device;
    const region = regionParam ? regionParam : this.region;
    const country = countryParam ? countryParam : this.country;
    const month = monthParam ? monthParam : this.month;
    const isFreemium = this.useAnonApi();
    const isFollower = this.isFollower;
    const includeSpoofing =
      includeSpoofingParam != undefined
        ? includeSpoofingParam
        : this.includeSpoofing;
    return new Promise(resolve => {
      if (isFreemium) {
        const queryParams = {
          region: region,
          os: device,
          month: month,
          country: country,
          isFreemium: isFreemium,
          isFollower: isFollower,
          includeSpoofing: includeSpoofing
        };
        const params = jQuery.param(queryParams);
        const urlString = `https://${this.getApigeeHost()}/api/v1/insights/ctv/${appid}?${params}`;

        const cacheKey = `${this.auth.getAuthState()}:${urlString}`;

        if (this.availableCountriesCache[cacheKey]) {
          const cacheData = this.availableCountriesCache[cacheKey];
          return resolve(JSON.parse(JSON.stringify(cacheData)));
        }

        this.http.get(urlString).subscribe(res => {
          this.storeCache(res, this.availableCountriesCache, cacheKey);
          resolve(res);
        });
      } else {
        let query = `WHERE appId = '${appid}' AND device = '${device}' AND region = '${region}'`;
        if (month && month.length > 0) {
          query += ` AND month = '${month}'`;
        }
        if (country && country.length > 0) {
          query += ` AND country = '${country}'`;
        }

        const urlString =
          `${this.getReportBaseUrl(
            '2018'
          )}/getWidget?q=${query}&reportId=ctvMrt&` +
          `widgetId=availableCountries&includeSpoofing=${includeSpoofing}`;

        const cacheKey = `${this.auth.getAuthState()}:${urlString}`;

        if (this.availableCountriesCache[cacheKey]) {
          const cacheData = this.availableCountriesCache[cacheKey];
          resolve(JSON.parse(JSON.stringify(cacheData)));
        } else {
          this.http.get(urlString).subscribe(res => {
            this.storeCache(res, this.availableCountriesCache, cacheKey);
            resolve(res);
          });
        }
      }
    });
  }
  getChangelog(appid: string) {
    const device = this.device;
    const country = this.region;
    const month = this.month;
    return new Promise(resolve => {
      let query = `WHERE appId = '${appid}' AND device = '${device}' AND region = '${country}'`;
      if (month && month.length > 0) {
        query += ` AND month = '${month}'`;
      }
      const urlString = `${this.getRatingsBaseUrl(
        '2018'
      )}/getCtvAppChangeLog?q=${query}&includeSpoofing=${this.includeSpoofing}`;
      if (this.appDetailsCacheData[urlString]) {
        setTimeout(() => {
          const cacheData = this.appDetailsCacheData[urlString];
          resolve(JSON.parse(JSON.stringify(cacheData)));
        }, 500);
      } else {
        this.http.get(urlString).subscribe(res => {
          this.storeCache(res, this.appDetailsCacheData, urlString);
          resolve(res);
        });
      }
    });
  }
  getAvailableMonths(reportId = 'ctvMrt'): Promise<any> {
    if (!this.availableMonthsCache[reportId]) {
      this.availableMonthsCache[reportId] = new Promise((resolve, reject) => {
        const urlString = `${this.getRatingsBaseUrl()}/getWidget?reportId=${reportId}&widgetId=availableMonths`;
        this.http.get(urlString).subscribe(
          res => {
            resolve(res);
          },
          error => {
            reject(error);
          }
        );
      });
    }
    return this.availableMonthsCache[reportId];
  }
  getFacets(filterString) {
    return new Promise(resolve => {
      let urlString =
        `${this.getRatingsBaseUrl('2018')}/getCtvFacets?` +
        `facet=pixalateRisk&facet=appAgeRisk&facet=spoofingRisk&facet=brandSafetyRisk&` +
        `facet=advisoriesRisk&facet=adultContentRisk&facet=alcoholContentRisk&facet=drugContentRisk&` +
        `facet=hateSpeechRisk&facet=offensiveContentRisk&facet=violenceContentRisk&facet=isAdsDetected&` +
        `facet=isAdsTxtEnabled&facet=isPrivateDomain&facet=hasTermsAndConditionLink&facet=hasPrivacyLink&` +
        `facet=isCvaaCompliant&facet=brandSafetyContentRisk&facet=imagesAdultContentRisk&` +
        `facet=isChannelAggregator&facet=hasAppPrivacyPolicyLink&facet=isExistInAppStore&` +
        `facet=imagesViolenceContentRisk&facet=coppaRisk&facet=coppaTargetChildrenRisk&facet=mfaRating&` +
        `facet=ownerDomain`;
      if (filterString && filterString.length > 0) {
        urlString = urlString.concat(`&${filterString}`);
      }

      if (this.facetsDataCache[urlString]) {
        resolve(this.facetsDataCache[urlString]);
      } else {
        this.http.get(urlString).subscribe(res => {
          this.storeCache(res, this.facetsDataCache, urlString);
          resolve(res);
        });
      }
    });
  }
  getFacetsBySearch(
    selectedDevice: string,
    selectedRegion: string,
    param: Array<string>,
    paramSearch: string,
    paramSearchValue: string,
    filterString?: string
  ) {
    return new Promise(resolve => {
      let urlString = `${this.getRatingsBaseUrl(
        '2018'
      )}/getCtvFacets?nocache=${Math.random()}`;
      if (selectedRegion) {
        urlString = `${this.getRatingsBaseUrl(
          '2018'
        )}/getCtvFacets?region=${selectedRegion}`;
      }
      if (param && param.length > 0) {
        for (const p of param) {
          urlString += `&facet=${p}`;
        }
      }
      if (
        paramSearch &&
        paramSearchValue &&
        paramSearch.length > 0 &&
        paramSearchValue.length > 0
      ) {
        urlString += `&${paramSearch}=${paramSearchValue}`;
      }
      if (selectedDevice) {
        const allDevice =
          'device=roku&device=firetv&device=samsung&device=tvos';
        const selectedDeviceString =
          selectedDevice === 'smartphone-tablet'
            ? allDevice
            : `device=${selectedDevice}`;
        if (selectedDevice && selectedDevice.length > 0) {
          urlString = urlString.concat(`&${selectedDeviceString}`);
        }
      }
      if (filterString && filterString.length > 0) {
        urlString = urlString.concat(`&${filterString}`);
      }
      if (this.facetsDataCache[urlString]) {
        resolve(this.facetsDataCache[urlString]);
      } else {
        this.http.get(urlString).subscribe(res => {
          this.storeCache(res, this.facetsDataCache, urlString);
          resolve(res);
        });
      }
    });
  }
  searchForApps(start: number, limit: number, filterString: string) {
    return new Promise(resolve => {
      let urlString: string;

      if (this.useAnonApi()) {
        urlString = `${this.getFreemiumBaseUrl()}/discovery/ctv/summary?start=${start}&limit=${limit}`;
      } else {
        urlString = `${this.getRatingsBaseUrl(
          '2018'
        )}/searchCtvApps?start=${start}&limit=${limit}`;
      }

      if (this.month) {
        urlString += `&month=${this.month}`;
      }
      if (filterString && filterString.length > 0) {
        urlString = urlString.concat(`&${filterString}`);
      }

      if (
        this.discoveryDataCache[urlString] &&
        urlString.indexOf('userId') < 0
      ) {
        resolve(this.discoveryDataCache[urlString]);
      } else {
        this.http.get(urlString).subscribe(res => {
          this.storeCache(res, this.discoveryDataCache, urlString);
          resolve(res);
        });
      }
    });
  }
  getRokuStats(filterString: string) {
    return new Promise(resolve => {
      let urlString = `${this.getRatingsBaseUrl(
        '2018'
      )}/ctvStats?nocache=${Math.random()}`;
      if (this.month) {
        urlString += `&month=${this.month}`;
      }
      if (filterString && filterString.length > 0) {
        urlString = urlString.concat(`&${filterString}`);
      }

      if (this.discoveryDataCache[urlString]) {
        resolve(this.discoveryDataCache[urlString]);
      } else {
        this.http.get(urlString).subscribe(res => {
          this.storeCache(res, this.discoveryDataCache, urlString);
          resolve(res);
        });
      }
    });
  }
  downloadCsv(filterString: string) {
    return new Promise(resolve => {
      const urlString = `${this.getRatingsBaseUrl()}/getExportUri?reportId=ctvSearch&${filterString}&isAsync=true`;
      this.http.get(urlString).subscribe(res => {
        resolve(res);
      });
    });
  }
  getTopCtvApps() {
    const url = `${this.getRatingsBaseUrl('2018')}/getTopCtvApps?cache=true`;
    return this.http.get(url);
  }
  checkImportQuota() {
    return new Promise(resolve => {
      const urlString = `${this.getRatingsBaseUrl()}/accountBalance`;
      this.http.get(urlString).subscribe(res => {
        resolve(res);
      });
    });
  }
  importCSV(removeDuplicates = false, formData: FormData) {
    const headers = new Headers();
    headers.append('Content-type', 'application/octet-stream');

    return new Promise(resolve => {
      const urlString = `${this.getRatingsBaseUrl()}/importCtvApps?removeDuplicates=${removeDuplicates}`;
      return this.http.post(urlString, formData).subscribe(
        res => {
          resolve(res);
        },
        err => {
          resolve(err);
        }
      );
    });
  }
  getImportList() {
    return new Promise(resolve => {
      const urlString = `${this.getRatingsBaseUrl('2018')}/listCtvImport`;
      this.http.get(urlString).subscribe(res => {
        this.storeCache(res, this.discoveryDataCache, urlString);
        resolve(res);
      });
    });
  }
  getReportData(device = 'roku') {
    const urlString = `${this.getRatingsBaseUrl(
      '2018'
    )}/loadReport?reportId=ctvSummary&device=${device}`;
    return new Promise(resolve => {
      if (!this.reportDataCache[device]) {
        return this.http.get(urlString).subscribe(response => {
          this.reportDataCache[device] = response;
          resolve(response);
        });
      } else {
        resolve(this.reportDataCache[device]);
      }
    });
  }
}
