import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import algoliasearch from 'algoliasearch/lite';
import { history as historyRouter } from 'instantsearch.js/es/lib/routers';
import querystring from 'querystring';
import {
  ALGOLIA_API_KEY,
  ALGOLIA_APP_ID,
  ALGOLIA_INDEX,
  Filter,
  FilterType,
  SEARCH_CONTEXT
} from '../ALGOLIA_CONFIG';
import { ActivatedRoute } from '@angular/router';
import { EventBusService } from 'app/services/EventBusService';
import { UiState, InstantSearchOptions } from 'instantsearch.js/es/types';
import { DatePipe } from '@angular/common';
import { DateRangePickerComponent } from '../ais-widgets/date-range-picker/date-range-picker.component';
import { AppNavigationService } from '../../../../services/app-navigation.service';
import { BehaviorSubject, Observable, of, Subscription, timer } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';
import { User } from 'app/models/User';
import { RefinementListService } from '../ais-widgets/refinement-list/refinement-list.service';
import {
  Breakpoint,
  MediaQueryService
} from 'app/services/media-query.service';

type IndexState = UiState[0];

interface CustomIndexState extends IndexState {
  numericMenu: {
    [key: string]: string;
  };
  range: {
    [key: string]: string;
  };
  filters: string;
}

@Component({
  selector: 'app-keyword-contextual-search',
  template: ''
})
export abstract class KcsBaseComponent implements OnInit, OnDestroy {
  datePipe = new DatePipe('en-US');

  @Input() scrollContainer: ElementRef;

  @Input() injectSearchQuery?: Observable<string>;
  @HostBinding('class.is-overlay') @Input() isOverlay = false;

  @Output() close: EventEmitter<void> = new EventEmitter();

  injectSearchQuerySubscription: Subscription;

  page = 0;
  query = '';
  scrollThrottleTimeout: NodeJS.Timeout;
  rawFilterQuery = '';
  formattedFilterQuery = '';
  optionalWords = undefined;

  showSideFiltersMobile = false;

  showingDelay$: Observable<boolean>;
  selectedFilters: Set<string> = new Set();
  selectedFiltersArray: string[] = ['all'];

  instantSearchState: BehaviorSubject<UiState> = new BehaviorSubject({});

  indexName = ALGOLIA_INDEX;
  abstract keywordFilters: Filter[];
  abstract filterKey: string;
  abstract filterUrlKey: string | undefined;
  abstract searchContext: SEARCH_CONTEXT;
  abstract config: InstantSearchOptions<{ [key: string]: CustomIndexState }>;

  public restrictSearchableAttributes: string[];
  public Breakpoint = Breakpoint;
  public bp$: Observable<Breakpoint>;

  constructor(
    private route: ActivatedRoute,
    private _eventBus: EventBusService,
    private _appnavService: AppNavigationService,
    private mediaQueryService: MediaQueryService,
    private user: User,
    private refinementListService: RefinementListService
  ) {
    this.bp$ = mediaQueryService.activeBreakpoint$;
  }

  ngOnInit(): void {
    if (this.isOverlay) {
      this.showingDelay$ = timer(300).pipe(
        first(),
        tap(() => console.log('DONE')),
        map(() => true)
      );

      if (this.injectSearchQuery) {
        this.injectSearchQuerySubscription = this.injectSearchQuery.subscribe(
          searchQuery => {
            this.setSearchParams(searchQuery);
          }
        );
      }

      this.selectedFilters.add('all');
    } else {
      this.showingDelay$ = of(true);
      const queryParam: string = this.route.snapshot.queryParams['query'];
      if (queryParam) {
        this.setSearchParams(queryParam);
      }

      this.route.queryParams.subscribe(queryParams => {
        if (queryParams['query']) {
          this.setSearchParams(queryParams['query']);
        } else {
          this.setSearchParams('');
        }
      });

      const filterParams: string =
        this.route.snapshot.queryParams[this.filterKey];
      if (filterParams && filterParams.length) {
        this.selectedFilters = new Set(filterParams.split(','));
      } else {
        this.selectedFilters = new Set(['all']);
      }

      setTimeout(() => {
        this._eventBus.showFeedback();
      }, 300);
    }
    this.selectedFiltersArray = [...this.selectedFilters.values()];
  }

  ngOnDestroy() {
    if (this.injectSearchQuerySubscription)
      this.injectSearchQuerySubscription.unsubscribe();
  }

  showCoppaFilter(): boolean {
    return (
      this.user.isFreemium() ||
      this.user.appCoppaPermissionsRiskEnabled() ||
      this.user.ctvCoppaPermissionsRiskEnabled()
    );
  }
  setSearchParams(queryParam: string) {
    if (this.query !== queryParam) {
      if (!queryParam) {
        this.query = '';
        this.optionalWords = [];
        return;
      }

      let optionalWords;
      if (!queryParam.match(/["']/g)) {
        if (queryParam.includes('query_upload')) {
          const keywordsFileContents = localStorage.getItem(
            'keywords-file-contents'
          );
          if (keywordsFileContents) {
            optionalWords = keywordsFileContents
              .split('\n')
              .join(',')
              .split('\t')
              .join(',')
              .split(' ')
              .join(',')
              .split(',')
              .filter(s => s.length > 0);
          }
        } else {
          optionalWords = queryParam.split(' ');
        }
      }
      this.optionalWords = optionalWords;
      this.query = queryParam.includes('query_upload')
        ? optionalWords.join(' ')
        : queryParam;
    }
  }

  onNavCloseClick() {
    this.showSideFiltersMobile = false;
    console.log('closing nav');
  }

  onNavOpenClick() {
    this.showSideFiltersMobile = true;
    console.log('opening nav');
  }

  onFilterClick(filterValue: string) {
    if (this.selectedFilters.has(filterValue)) {
      this.selectedFilters.delete(filterValue);

      if (this.selectedFilters.size === 0) {
        this.selectedFilters.add('all');
      }
    } else {
      if (filterValue === 'all') {
        this.selectedFilters.clear();
      } else {
        this.selectedFilters.delete('all');
      }

      this.selectedFilters.add(filterValue);
    }
    this.selectedFiltersArray = [...this.selectedFilters.values()];
    this.handlePillFilterChange([...this.selectedFilters.values()].join(','));
  }

  handlePillFilterChange(filter: string) {
    this.page = 0;
    this.rawFilterQuery = filter;
    this.formattedFilterQuery = this.getFormattedFilterQuery(filter.split(','));
  }

  getFilterValue(filter: Filter, key: string, indexState: CustomIndexState) {
    let val;
    if (
      filter.type === FilterType.RefinementList &&
      indexState.refinementList &&
      indexState.refinementList[key]
    ) {
      val = indexState.refinementList[key];
    } else if (
      filter.type === FilterType.NumericMenuSelect &&
      indexState.numericMenu &&
      indexState.numericMenu[key]
    ) {
      val = indexState.numericMenu[key];
    } else if (
      filter.type === FilterType.DateRange &&
      indexState.range &&
      indexState.range[key]
    ) {
      // const dates = indexState.range[key].split(':');
      // val = this.datePipe.transform(new Date(Number.parseInt(dates[0], 10)), 'ddMMyyyy') + ':' +
      //   this.datePipe.transform(new Date(Number.parseInt(dates[1], 10)), 'ddMMyyyy');
      val = indexState.range[key];
    }
    return val;
  }

  setIndexState(
    filter: Filter,
    locvars: qs.ParsedQs,
    indexState: CustomIndexState
  ) {
    const key = filter.attribute;
    const locvarsKey = filter?.urlKey ? filter?.urlKey : filter.attribute;
    if (filter.type === FilterType.RefinementList) {
      const hasVar: any = Array.isArray(locvars[locvarsKey])
        ? locvars[locvarsKey]
        : [locvars[locvarsKey]];
      if (hasVar[0]) {
        indexState.refinementList[key] = hasVar;
      }
    } else if (
      filter.type === FilterType.NumericMenuSelect &&
      locvars[locvarsKey] !== undefined
    ) {
      indexState.numericMenu[key] = `${locvars[locvarsKey]}`;
    } else if (
      filter.type === FilterType.DateRange &&
      locvars[locvarsKey] !== undefined
    ) {
      // const dates = `${locvars[key]}`.split(':');
      // const startDate = DateRangePickerComponent.parseDate(dates[0]);
      // const endDate =  DateRangePickerComponent.parseDate(dates[1]);
      // indexState.range[key] = `${startDate.getTime()}:${endDate.getTime()}`;
      indexState.range[key] = `${locvars[locvarsKey]}`;
    }
    return indexState;
  }

  makeConfig(): InstantSearchOptions<{ [key: string]: CustomIndexState }> {
    const config: InstantSearchOptions<{ [key: string]: CustomIndexState }> = {
      searchClient: algoliasearch(ALGOLIA_APP_ID, ALGOLIA_API_KEY),
      indexName: this.indexName,
      onStateChange: ({ uiState, setUiState }) => {
        console.log('next state change');
        this.instantSearchState.next(uiState);
        setUiState(uiState);
      }
    };

    if (this.isOverlay) {
      config.routing = {
        router: historyRouter({
          createURL: ({ qsModule, routeState, location }) => {
            const indexState: any = routeState[this.indexName];
            if (indexState) {
              const urlParts = location.href.match(/^(.*?)\/search/);
              const pathname = location.pathname.slice(1);
              if (urlParts) {
                const baseUrl = `${urlParts ? urlParts[1] : ''}/`;
                const queryParameters: any = {};
                if (indexState.query) {
                  queryParameters.query = indexState.query;
                }
                this.keywordFilters.forEach(filter => {
                  if (filter.subCategories) {
                    filter.subCategories.forEach(subfilter => {
                      const val = this.getFilterValue(
                        subfilter,
                        subfilter.attribute,
                        indexState
                      );
                      if (val) {
                        queryParameters[
                          subfilter?.urlKey
                            ? subfilter?.urlKey
                            : subfilter.attribute
                        ] = val;
                      }
                    });
                  } else {
                    const val = this.getFilterValue(
                      filter,
                      filter.attribute,
                      indexState
                    );
                    if (val) {
                      queryParameters[
                        filter?.urlKey ? filter?.urlKey : filter.attribute
                      ] = val;
                    }
                  }
                });

                if (indexState[this.filterKey]) {
                  queryParameters[
                    this.filterUrlKey ? this.filterUrlKey : this.filterKey
                  ] = indexState[this.filterKey];
                }

                const queryString = qsModule.stringify(queryParameters, {
                  addQueryPrefix: true,
                  arrayFormat: 'repeat'
                });
                const ret = `${baseUrl}${pathname}${queryString}`;
                return ret;
              } else {
                return location.pathname;
              }
            } else {
              return location.pathname + location.search;
            }
          },
          parseURL: ({ qsModule, location }) => {
            const locvars: any = qsModule.parse(location.search.slice(1));
            const query: any = locvars.query ? locvars.query : '';
            const page = locvars.page
              ? Number.parseInt(`${locvars.page}`, 10)
              : 1;
            let indexState: CustomIndexState = {
              // @ts-ignore
              query,
              page,
              refinementList: {},
              numericMenu: {},
              range: {},
              filters: ''
            };

            this.keywordFilters.forEach(filter => {
              if (filter.subCategories) {
                filter.subCategories.forEach(subfilter => {
                  indexState = this.setIndexState(
                    subfilter,
                    locvars,
                    indexState
                  );
                });
              } else {
                indexState = this.setIndexState(filter, locvars, indexState);
              }
            });

            if (
              locvars[this.filterUrlKey ? this.filterUrlKey : this.filterKey]
            ) {
              this.rawFilterQuery =
                locvars[this.filterUrlKey ? this.filterUrlKey : this.filterKey];
              this.formattedFilterQuery = this.getFormattedFilterQuery(
                this.rawFilterQuery.split(',')
              );
              indexState[this.filterKey] = this.formattedFilterQuery;
            }
            return {
              [this.indexName]: indexState
            };
          }
        }),
        stateMapping: {
          stateToRoute: uiState => {
            const locvars = querystring.parse(location.search.slice(1));
            const rangeMenu = {};
            if (uiState[this.indexName].range) {
              this.keywordFilters.forEach(filter => {
                if (filter.subCategories) {
                  filter.subCategories
                    .filter(
                      subfilter => subfilter.type === FilterType.DateRange
                    )
                    .forEach(subfilter => {
                      if (uiState[this.indexName].range[subfilter.attribute]) {
                        const dateRange =
                          uiState[this.indexName].range[subfilter.attribute];
                        const dates = dateRange.split(':');
                        rangeMenu[subfilter.attribute] =
                          this.datePipe.transform(dates[0], 'ddMMyyyy') +
                          ':' +
                          this.datePipe.transform(dates[1], 'ddMMyyyy');
                      }
                    });
                } else if (
                  filter.type === FilterType.DateRange &&
                  uiState[this.indexName].range[filter.attribute]
                ) {
                  const dateRange =
                    uiState[this.indexName].range[filter.attribute];
                  const dates = dateRange.split(':');
                  rangeMenu[filter.attribute] =
                    this.datePipe.transform(dates[0], 'ddMMyyyy') +
                    ':' +
                    this.datePipe.transform(dates[1], 'ddMMyyyy');
                }
              });
            }
            const indexState: CustomIndexState = {
              query: `${locvars.query || ''}`,
              page: uiState[this.indexName].page,
              refinementList: uiState[this.indexName].refinementList,
              numericMenu: uiState[this.indexName].numericMenu,
              range: rangeMenu,
              filters: ''
            };
            if (uiState[this.indexName].configure) {
              const configure = uiState[this.indexName].configure;
              if (configure.filters) {
                indexState[this.filterKey] = this.rawFilterQuery;
              }
            }
            return {
              [this.indexName]: indexState
            };
          },
          routeToState: routeState => {
            const locvars = querystring.parse(location.search.slice(1));
            const indexState = routeState[this.indexName];
            const rangeMenu = {};
            if (routeState[this.indexName].range) {
              this.keywordFilters.forEach(filter => {
                if (filter.subCategories) {
                  filter.subCategories
                    .filter(
                      subfilter => subfilter.type === FilterType.DateRange
                    )
                    .forEach(subfilter => {
                      if (
                        routeState[this.indexName].range[subfilter.attribute]
                      ) {
                        const dateRange = `${
                          locvars[
                            subfilter?.urlKey
                              ? subfilter?.urlKey
                              : subfilter.attribute
                          ]
                        }`;
                        const dates = dateRange.split(':');
                        rangeMenu[
                          subfilter.attribute
                        ] = `${DateRangePickerComponent.parseDate(
                          dates[0]
                        ).getTime()}:${DateRangePickerComponent.parseDate(
                          dates[1]
                        ).getTime()}`;
                      }
                    });
                } else if (
                  filter.type === FilterType.DateRange &&
                  routeState[this.indexName].range[filter.attribute]
                ) {
                  const dateRange = `${
                    locvars[filter?.urlKey ? filter?.urlKey : filter.attribute]
                  }`;
                  const dates = dateRange.split(':');
                  rangeMenu[
                    filter.attribute
                  ] = `${DateRangePickerComponent.parseDate(
                    dates[0]
                  ).getTime()}:${DateRangePickerComponent.parseDate(
                    dates[1]
                  ).getTime()}`;
                }
              });
            }

            const outState: CustomIndexState = {
              query: indexState.query,
              page: indexState.page,
              configure: indexState.configure,
              refinementList: indexState.refinementList,
              numericMenu: indexState.numericMenu,
              range: rangeMenu,
              filters: indexState.filters
            };

            if (indexState[this.filterKey]) {
              outState.filters = indexState[this.filterKey];
            }
            return {
              [this.indexName]: outState
            };
          }
        }
      };
    }

    return config;
  }

  getFormattedFilterQuery(selectedFilters: string[]) {
    const filterSet = new Set();
    for (const filter of selectedFilters) {
      switch (filter) {
        case 'all':
          return '';
        case 'allMobile':
          filterSet.add(`${this.filterKey}:ios`);
          filterSet.add(`${this.filterKey}:android`);
          break;
        case 'allCtv':
          filterSet.add(`${this.filterKey}:firetv`);
          filterSet.add(`${this.filterKey}:roku`);
          filterSet.add(`${this.filterKey}:samsung`);
          filterSet.add(`${this.filterKey}:tvos`);
          break;
        default:
          filterSet.add(`${this.filterKey}:${filter}`);
          break;
      }
    }

    return [...filterSet.values()].join(' OR ');
  }

  refine(attribute: string, value: string) {
    this.refinementListService.refine({ attribute, value });
  }

  isFreemiumUser() {
    return this.user.isFreemium();
  }

  public closeButtonClick() {
    this.close.emit();
  }
}
