import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api-service';

@Injectable({
  providedIn: 'root'
})
export class AlgoliaService extends BaseApiService {
  constructor(private http: HttpClient) {
    super();
  }

  download(filterString: string) {
    return new Promise(resolve => {
      const urlString = `${this.getRatingsBaseUrl()}/getExportUri?${filterString}&isAsync=true`;
      this.http.get(urlString).subscribe(res => {
        resolve(res);
      });
    });
  }
}
