import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from '../../pipes/pipes.module';
import { RouterModule } from '@angular/router';
import { AppRankingsComponent } from './app-rankings/app-rankings.component';
import { FollowUnlockComponent } from './follow-unlock/follow-unlock.component';
import { ChangelogBtnComponent } from './changelog-btn/changelog-btn.component';
import { AppChangelogComponent } from './app-changelog/app-changelog.component';
import { AppChangelogService } from './app-changelog/app-changelog.service';
import { InfoTooltipComponent } from './info-tooltip/info-tooltip.component';
import { DirectivesModule } from 'app/directives/directives.module';
import { RankingsAnonFormComponent } from './rankings-anon-form/rankings-anon-form.component';
import { RiskHeatBarComponent } from './risk-heat-bar/risk-heat-bar';
import { RiskHeatMeterComponent } from './risk-heat-meter/risk-heat-meter';
import { PaginatorComponent } from './paginator/paginator.component';
import { ScrollToTopComponent } from './scroll-to-top/scroll-to-top.component';
import { SwiperModule } from 'swiper/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NotificationSettingsComponent } from './notification-settings/notification-settings.component';
import { SliderToggleComponent } from './slider-toggle/slider-toggle.component';

@NgModule({
  imports: [
    CommonModule,
    PipesModule,
    RouterModule,
    DirectivesModule,
    SwiperModule,
    ReactiveFormsModule,
    FormsModule
  ],
  exports: [
    AppRankingsComponent,
    FollowUnlockComponent,
    ChangelogBtnComponent,
    AppChangelogComponent,
    InfoTooltipComponent,
    RankingsAnonFormComponent,
    RiskHeatBarComponent,
    RiskHeatMeterComponent,
    PaginatorComponent,
    ScrollToTopComponent,
    NotificationSettingsComponent,
    SliderToggleComponent
  ],
  declarations: [
    AppRankingsComponent,
    FollowUnlockComponent,
    ChangelogBtnComponent,
    AppChangelogComponent,
    InfoTooltipComponent,
    RankingsAnonFormComponent,
    RiskHeatBarComponent,
    RiskHeatMeterComponent,
    PaginatorComponent,
    ScrollToTopComponent,
    NotificationSettingsComponent,
    SliderToggleComponent
  ],
  providers: [AppChangelogService]
})
export class SharedModule {}
